import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import { Breadcrumb, BreadcrumbItem, Col, Row, Button } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Images from '../../components/Images';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { constants } from '../../helper/constants';
import { useEditCharityMutation, useGetCharityDetailQuery } from '../../Redux/api/charityApi';
import Loader from '../../components/loader';
import { showToast } from '../../helper/swal';
import { usePreSignedUrlMutation, useUpdateImageMutation } from '../../Redux/api/preSignedUrlApi';

const EditCharity = () => {
  const initialValues = {
    title: "",
    ein: "",
    city: "",
    state: "",
    zipCode: "",
    description: "",
    status: "",
    image: null
  }

  const [imgUrl, setImgUrl] = useState(null);
  const [formData, setFormData] = useState(initialValues);
  const [fileSelected, setFileSelected] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const { refetch: refetchCharityDetailData, data: charityDetailData, isFetching: charityDetailFetching, isSuccess: charityDetailSuccess, error: charityDetailError } = useGetCharityDetailQuery({
    charityId: id,
  }, { skip: !id });

  const [editCharity, { data: editCharityData, isLoading: isEditCharityLoading, isSuccess: isEditCharitySuccess, error: editCharityError }] = useEditCharityMutation();
  const [preSignedUrl, { isLoading: isPreSignedUrlLoading }] = usePreSignedUrlMutation();
  const [updateImage, { isLoading: isUpdateImageLoading }] = useUpdateImageMutation();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(constants?.validations?.required).max(100, constants?.validations?.maxChar100),
    ein: Yup.string().required(constants?.validations?.required).matches(/^[0-9]+$/, constants?.validations?.numericOnly).max(25, constants?.validations?.maxDigit25),
    city: Yup.string().required(constants?.validations?.required).max(100, constants?.validations?.maxChar100),
    state: Yup.string().required(constants?.validations?.required).max(100, constants?.validations?.maxChar100),
    zipCode: Yup.string().required(constants?.validations?.required).matches(/^[0-9-]+$/, constants?.validations?.numericAndHyphensOnly).max(25, constants?.validations?.maxDigit25),
    description: Yup.string().required(constants?.validations?.required).max(1000, constants?.validations?.maxChar1000),
    status: Yup.string().required(constants?.validations?.required),
    image: Yup.string().nullable(),
    // image: Yup.string().matches(/^(|.*\.(jpg|jpeg|png))$/, 'Invalid file format'),
    // image: Yup
    //   .string()
    //   .nullable()
    //   .notRequired()
    //   .test('fileType', 'Invalid file format', (value) => {
    //     if (value === null || value === "") {
    //       return true;
    //     }
    //     return /\.(jpg|jpeg|png)$/.test(value.toLowerCase());
    //   }),
    // image: Yup.mixed().test(
    //   'fileType',
    //   'Invalid file format',
    //   (value) => {
    //     if (!value) {
    //       console.log(value, 'value');
    //       return true; // Allow null or empty value
    //     }

    //     const acceptedFormats = ['image/png', 'image/jpeg', 'image/jpg'];
    //     return acceptedFormats.includes(value.type);
    //   }
    // )
  })

  // Upload Image
  const handleFileChange = (event, form, field) => {
    if (event.target.files && event.target.files.length) {
      setFileSelected(true);
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        form.setFieldValue(field.name, file);
        setImgUrl(URL.createObjectURL(file));
      };
      reader.readAsDataURL(file);
    }
  };

  // Update Chairty
  const handleSubmit = async (values) => {
    if (fileSelected) {
      const imgObj = await preSignedUrl({
        type: "put",
        files: [
          {
            folderName: "charity",
            fileName: values.image.name
          }
        ]
      })
      await updateImage({
        url: imgObj?.data?.data[0]?.signedUrl,
        file: values.image
      })
    }
    const obj = {
      title: values?.title,
      ein: values?.ein,
      city: values?.city,
      state: values?.state,
      zipCode: values?.zipCode,
      description: values?.description,
      status: values?.status,
      image: fileSelected ? 'charity/' + values.image.name : charityDetailData?.data?.image ? charityDetailData?.data?.image : null,
    }
    await editCharity({ data: obj, updateCharityId: id });
  }

  // Refetch Charity Detail
  useEffect(() => {
    refetchCharityDetailData();
  }, [refetchCharityDetailData])

  // Get Charity Detail
  useEffect(() => {
    if (charityDetailSuccess) {
      setImgUrl(charityDetailData?.data?.image && process.env.REACT_APP_BASE_IMG_URL + charityDetailData?.data?.image);
      setFormData({
        title: charityDetailData?.data?.title,
        ein: charityDetailData?.data?.ein,
        city: charityDetailData?.data?.city,
        state: charityDetailData?.data?.state,
        zipCode: charityDetailData?.data?.zipCode,
        description: charityDetailData?.data?.description,
        status: charityDetailData?.data?.status,
        image: charityDetailData?.data?.image
      })
    } else if (charityDetailError) {
      showToast(charityDetailError?.data?.message, "error", true)
    }
  }, [charityDetailSuccess, charityDetailData?.data, charityDetailError]);


  // Update Charity Response
  useEffect(() => {
    if (isEditCharitySuccess) {
      if (editCharityData?.success) {
        showToast(editCharityData?.message, "success", false, 3000);
        navigate("/charity");
      } else {
        showToast(editCharityData?.message, "error", true)
      }
    } else if (editCharityError) {
      showToast(editCharityError?.data?.message, "error", true)
    }
  }, [isEditCharitySuccess, editCharityData?.success, editCharityData?.message, editCharityError, isEditCharityLoading, navigate])

  return (
    <Layout>
      {(charityDetailFetching || isEditCharityLoading || isPreSignedUrlLoading || isUpdateImageLoading) ? <Loader /> : null}
      <div className='conent-header'>
        <h1 className='title-h1'>Edit Charity</h1>
        <Breadcrumb>
          <BreadcrumbItem linkAs="span">
            <Link to="/charity">All Charities</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Edit Charity</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <Formik initialValues={formData} enableReinitialize validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className='card'>
                <div className='card-body'>
                  <Row>
                    <Col xl={2} md={3}>
                      <div className="form-group d-flex align-items-center flex-column mb-0">
                        <div className="img-thumb-box">
                          <div className="img-thumb">
                            <img src={imgUrl ? imgUrl : Images.PLACEHOLDER_IMG} alt="" />
                          </div>
                          <Field name="image">
                            {({ field, form }) => (
                              <label className="upload-file-wrap">
                                <i className="fas fa-plus"></i>
                                <input type="file" name="image" accept="image/png, image/jpeg,image/jpg" onChange={(event) => { handleFileChange(event, form, field) }} />
                              </label>
                            )}
                          </Field>
                        </div>
                        <ErrorMessage name="image" component="div" className="error-text" />
                      </div>
                    </Col>
                    <Col xl={10} md={9}>
                      <Row>
                        <Col sm={4}>
                          <div className="mb-3">
                            <label className='form-label'>Charity Name</label>
                            <Field type="text" className="form-control" name="title" />
                            <ErrorMessage name="title" component="div" className="error-text" />
                          </div>
                        </Col>
                        <Col sm={4}>
                          <div className="mb-3">
                            <label className='form-label'>EIN Number</label>
                            <Field type="text" className="form-control" name="ein" />
                            <ErrorMessage name="ein" component="div" className="error-text" />
                          </div>
                        </Col>
                        <Col sm={4}>
                          <div className="mb-3">
                            <label className='form-label'>Status</label>
                            <Field as="select" name="status" className="form-control">
                              <option value="">Select Status</option>
                              {constants?.status?.map((item, index) => (
                                <option value={item.value} key={index}>{item.name}</option>
                              ))}
                            </Field>
                            <ErrorMessage name="status" component="div" className="error-text" />
                          </div>
                        </Col>
                        <Col sm={4}>
                          <div className="mb-3">
                            <label className='form-label'>City</label>
                            <Field type="text" className="form-control" name="city" />
                            <ErrorMessage name="city" component="div" className="error-text" />
                          </div>
                        </Col>
                        <Col sm={4}>
                          <div className="mb-3">
                            <label className='form-label'>State</label>
                            <Field type="text" className="form-control" name="state" />
                            <ErrorMessage name="state" component="div" className="error-text" />
                          </div>
                        </Col>
                        <Col sm={4}>
                          <div className="mb-3">
                            <label className='form-label'>Zip Code</label>
                            <Field type="text" className="form-control" name="zipCode" />
                            <ErrorMessage name="zipCode" component="div" className="error-text" />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <label className='form-label'>Description</label>
                            <Field as="textarea" className="form-control" name="description" />
                            <ErrorMessage name="description" component="div" className="error-text" />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className='text-center mt-4'>
                <Button type="submit" variant="primary" className='btn-size-xs'>Save</Button>
                <Link to="/charity" className='btn btn-secondary btn-size-xs ms-2'>Cancel</Link>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Layout>
  )
}

export default EditCharity