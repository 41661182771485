
import logo from "../assets/images/logo.svg";
import PlaceholderImg from "../assets/images/placeholder-img.png";
import logoBlack from "../assets/images/logo-black.svg";
import userImg from "../assets/images/user.svg";

const Images = Object.freeze({
  LOGO: logo,
  LOGO_BLACK: logoBlack,
  PLACEHOLDER_IMG: PlaceholderImg,
  USER_PLACEHOLDER: userImg
});

export default Images;
