import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import FollowedTabData from '../tabs/FollowedTabData';
import SubscribedTabData from '../tabs/SubscribedTabData';

const FollowedSubscribed = ({ openModal, closeModal }) => {
  const [tabkey, setTabKey] = useState('followed');
  return (
    <Modal
      show={openModal}
      onHide={closeModal}
      centered
    >
      <Modal.Header>
        <Modal.Title>Joined Tank</Modal.Title>
        <button type='button' className='btn btn-close-modal' onClick={closeModal}>
          <i className="fa-solid fa-xmark"></i>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className='tab-container'>
          <Tabs
            id="followedTab"
            activeKey={tabkey}
            onSelect={(k) => setTabKey(k)}
            className="mb-4 justify-content-center tabs-dark"
          >
            <Tab eventKey="followed" title="Joined">
              {tabkey === "followed" &&
                <FollowedTabData />
              }
            </Tab>
            <Tab eventKey="subscribed" title="Subscribed">
              {tabkey === "subscribed" &&
                <SubscribedTabData />
              }
            </Tab>
          </Tabs>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default FollowedSubscribed