import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { logoutUser, updateRefeshTokenAndToken } from "../Redux/slice/authSlice";
import noTokenEndpoint from "./noTokenEndpoint";
import { isEmpty } from "lodash";
export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState, endpoint}) => {
    const { user } = getState().auth;
    const data = noTokenEndpoint.filter((rec) => {
      return rec.name === endpoint
    });
    if (isEmpty(data)) {
      const token = user.accessToken;
      headers.set('authorization', token);
    } else {
      headers.set('client_secret', process.env.REACT_APP_CLIENT_SECRET);
    }
    return headers
  }
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 498) {
    const { user } = api.getState().auth;

    const refreshResult = await fetch(process.env.REACT_APP_API_URL+'/user/v1/access-token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'client_secret': process.env.REACT_APP_CLIENT_SECRET,
      },
      body: JSON.stringify({
        refreshToken: user.refreshToken
      })
    });
    
    //if get new token
    if (refreshResult.ok) {
      const data = await refreshResult.json();
      // store the new token
      api.dispatch(updateRefeshTokenAndToken(data?.data))
      localStorage.setItem('user', JSON.stringify(data?.data?.accessToken));
      // retry the initial query
      result = await baseQuery(args, api, extraOptions)
    } else {
      api.dispatch(logoutUser())
    }

  }
  return result
}