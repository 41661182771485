import Swal from "sweetalert2";

export const showToast = (title, icon, button, timer, text) => {
  Swal.fire({
    title: title,
    icon: icon,
    showConfirmButton: button,
    timer: timer || null,
    text: text || null,
  });
}