import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Images from '../../components/Images';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import { useResendOtpMutation, useVerifyOtpMutation } from '../../Redux/api/authApi';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../helper/swal';
import { constants } from '../../helper/constants';
import { setForgotToken} from '../../Redux/slice/verifyOtpSlice';
import Loader from '../../components/loader';

const VerifyOtp = () => {
  const [counter, setCounter] = useState(60);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const forgotToken = useSelector((state) => state.verifyOtp.forgotToken);
  const [verifyOtp, { data: verifyOtpData, isLoading: isVerifyOtpLoading, isSuccess: isVerifyOtpSuccess, error: verifyOtpError }] = useVerifyOtpMutation();
  const [resendOtp, { data: resendOtpData, isLoading: isResendOtpLoading, isSuccess: isResendSuccess, error: resendOtpError }] = useResendOtpMutation();

  const initialValues = {
    otp: '',
  }

  /**
 * OTP Form Validation Schema
 */
  const otpSchema = Yup.object().shape({
    otp: Yup.number().required(constants?.validations?.required).typeError(constants?.validations?.validOtp).test('max-length', (constants?.validations?.otpDigit), (value) => value && value.toString().length === 6
    ),
  });

  // Handle Submit
  const handleSubmit = async (values) => {
    const obj = {
      otp: values.otp,
      verificationToken: forgotToken,
      deviceType: "IOS",
      deviceId: "2",
      deviceToken: "2",
    }
    await verifyOtp(obj);
  }

  // Handle Resend Otp
  const handleResendOtp = async () => {
    const obj = {
      verificationToken: forgotToken,
    }
    await resendOtp(obj);
  }

  useEffect(() => {
    const timer = counter > 0 &&
      setTimeout(() => {
        setCounter(counter - 1)
      }, 1000);
    return () => clearTimeout(timer)
  }, [counter])

  
  // Resend Otp Response
  useEffect(() => {
    if (isResendSuccess) {
      setCounter(60);
      showToast(resendOtpData?.message, "success", false, 1500);
      dispatch(setForgotToken(resendOtpData?.data?.verificationToken));
    } else if (resendOtpError) {
      showToast(resendOtpError?.data?.message, "error", true)
    }
  }, [isResendSuccess, resendOtpError, resendOtpData, dispatch]);


  // Verify Otp Response
  useEffect(() => {
    if (isVerifyOtpSuccess) {
      showToast(verifyOtpData?.message, "success", false, 3000);
      dispatch(setForgotToken(verifyOtpData?.data?.verificationToken));
      navigate("/reset-password");
    } else if (verifyOtpError) {
      showToast(verifyOtpError?.data?.message, "error", true)
    }
  }, [isVerifyOtpSuccess, verifyOtpError, verifyOtpData, navigate, dispatch]);


  return (
    <>
      {(isResendOtpLoading) && <Loader />}
      <div className="auth-page-wrapper">
        <Container>
          <div className="auth-box">
            <div className="text-center mb-4">
              <img src={Images.LOGO} className="header-logo" alt="Brand Logo" />
            </div>
            <div className="auth-card">
              <div className="auth-card-body">
                <div className="text-center mb-4">
                  <h1>Verify Otp</h1>
                  <p>Enter the OTP you received on your registered email address.</p>
                </div>
                <Formik initialValues={initialValues} validationSchema={otpSchema} onSubmit={handleSubmit}>
                  {({ handleSubmit, errors, setFieldValue }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <label className="form-label">OTP</label>
                          <Field type="text" maxLength="6" className="form-control" name="otp" />
                          <ErrorMessage name="otp" component="div" className="error-text" />
                        </div>
                        <div className={counter ? "text-primary fw-bold text-center mt-4" : 'text-primary fw-bold text-center mt-4 invisible'}>{`00:${counter}`}</div>
                        <div className="my-4">
                          <button type="submit" className={isVerifyOtpLoading ? "btn btn-primary w-100 pe-none" : "btn btn-primary w-100"}>
                            {isVerifyOtpLoading ?
                              <>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                Loading...
                              </> :
                              <span>Submit</span>
                            }
                          </button>
                        </div>
                        <div className="text-center">
                          <div>Didn't receive Otp?<button type="button" className={counter ? 'btn-resend ms-1 disabled' : 'btn-resend ms-1'} onClick={() => handleResendOtp()}>Resend</button></div>
                        </div>
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

export default VerifyOtp