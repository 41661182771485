
import { createApi} from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../../utils/baseQuery";

export const contentApi = createApi({
  reducerPath: "contentApi",
  baseQuery: baseQueryWithReauth,

  endpoints: (builder) => ({
    getPostList: builder.query({
      query: (data) => {
        const { pageNo, pageSize, postType, searchParam } = data;
        const url = `pageNo=${pageNo}&pageSize=${pageSize}${postType ? `&type=${postType}` : ''}${searchParam ? `&search=${searchParam}` : ''}`
        return {
          url: `/post/v1/manage-content?${url}`
        }
      }
    }),
    getPostDetail: builder.query({
      query: (data) => {
        const { postId } = data;
        const url = postId;
        return {
          url: `/post/v1/posts/?postId=${url}`
        }
      }
    }),
    updatePostStatus: builder.mutation({
      query: (body) => {
        const { data } = body;
        return {
          url: `/post/v1/update-post-status`,
          method: 'PUT',
          headers: {},
          body: data
        }
      },
    }),
    getCommentList: builder.query({
      query: (data) => {
        const { pageNo, pageSize, postId } = data;
        const url = `pageNo=${pageNo}&pageSize=${pageSize}`
        return {
          url: `/post/v1/post/${postId}/comments?${url}`
        }
      }
    }),
    getCommentDetail: builder.query({
      query: (data) => {
        const {commentId } = data;
        return {
          url: `/post/v1/comment/${commentId}`
        }
      }
    }),
    deleteComment: builder.mutation({
      query: (body) => {
        const { data, commentId } = body;
        return {
          url: `/post/v1/comment/${commentId}`,
          method: 'PUT',
          headers: {},
          body: data
        }
      },
    }),
  }),
});

export const {
  useGetPostListQuery,
  useGetPostDetailQuery,
  useUpdatePostStatusMutation,
  useGetCommentListQuery,
  useDeleteCommentMutation,
  useGetCommentDetailQuery
} = contentApi;