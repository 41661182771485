import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import { Col, Form, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { constants } from '../../helper/constants';
import { useDeleteUserMutation, useGetUserListQuery, useUserStatusMutation } from '../../Redux/api/userApi';
import Loader from '../../components/loader';
import { showToast } from '../../helper/swal';
import NoRecordsFound from '../../components/shared/NoRecordsFound';
import { capitalizeStr } from '../../helper/capitalizeStr';
import { debounce } from 'lodash';
import Swal from 'sweetalert2';
import CommonPagination from '../../components/shared/CommonPagination';
import { currencyFormat } from '../../helper/numberFormat';

const UserManagement = () => {
  const [pageNo, setPageNo] = useState(constants?.common?.pageNo);
  const [pageSize, setPageSize] = useState(constants?.common?.pageSize);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const [allUsersList, setAllUsersList] = useState([]);

  const { refetch: refetchUserList, data: userListData, isFetching: isUserListFetching, isSuccess: isUserListSuccess, error: userListError } = useGetUserListQuery({
    pageNo: pageNo + 1,
    pageSize: pageSize,
    userStatus: status,
    searchParam: searchText
  });

  const [userStatus, { data: userStatusData, isLoading: isUserStatusLoading, isSuccess: isUserStatusSuccess, error: userStatusError }] = useUserStatusMutation();

  const [deleteUser, { data: deleteUserData, isLoading: isDeleteUserLoading, isSuccess: isDeleteUserSuccess, error: deleteUserError }] = useDeleteUserMutation();

  // Page Change
  const handlePageChange = (page) => {
    const selectedPageNo = setPageNo(page.selected);
    return selectedPageNo;
  }

  // Search User
  const handleUserSearch = debounce((e) => {
    setSearchText(e.target.value);
    setPageNo(0);
  }, 500)


  // Block Unblock User
  const handleUserStatus = (getActionType, getUserId) => {
    Swal.fire({
      title: getActionType === "block" ? "Block" : "Unblock",
      text: getActionType === "block" ? constants?.validations?.blockUserText : constants?.validations?.unblockUserText,
      icon: 'question',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      allowOutsideClick: false
    }).then(async (result) => {
      if (result.isConfirmed && result.value) {
        await userStatus({ userId: getUserId })
      }
    })
  }

  //Get User Status
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    setPageNo(0);
  }


  // Delete User
  const handleDeleteUser = (getUserId) => {
    Swal.fire({
      title: constants?.validations?.deleteUserTitle,
      text: constants?.validations?.deleteUserText,
      icon: 'question',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      allowOutsideClick: false
    }).then(async (result) => {
      if (result.isConfirmed && result.value) {
        await deleteUser({ userId: getUserId })
      }
    })
  }

  useEffect(() => {
    // Refetch User list on page change
    refetchUserList();
  }, [pageNo, pageSize, status, searchText, refetchUserList])


  // User Status Response
  useEffect(() => {
    if (isUserStatusSuccess) {
      if (userStatusData?.success) {
        showToast(userStatusData?.message, "success", false, 2500);
        refetchUserList();
      } else {
        showToast(userStatusData?.message, "error", true)
      }
    } else if (userStatusError) {
      showToast(userStatusError?.data?.message, "error", true)
    }
  }, [userStatusData, isUserStatusSuccess, userStatusError, refetchUserList])


  // Delete User Response
  useEffect(() => {
    if (isDeleteUserSuccess) {
      if (deleteUserData?.success) {
        showToast(deleteUserData?.message, "success", false, 2500);
        refetchUserList();
      } else {
        showToast(deleteUserData?.message, "error", true)
      }
    } else if (deleteUserError) {
      showToast(deleteUserError?.data?.message, "error", true)
    }
  }, [deleteUserData, isDeleteUserSuccess, deleteUserError, refetchUserList])

  // User List Response
  useEffect(() => {
    if (isUserListSuccess) {
      setAllUsersList(userListData?.data?.users);
      setTotalCount(userListData?.data?.totalCount)
    } else if (userListError) {
      showToast(userListError?.data?.message, "error", true)
    }
  }, [userListData, isUserListSuccess, userListError])

  return (
    <Layout>
      {(isUserListFetching || isUserStatusLoading || isDeleteUserLoading) && <Loader />}
      <div className='conent-header'>
        <h1 className='title-h1'>All Users</h1>
      </div>
      <div className='card'>
        <div className='card-body'>
          <div className='card-filter'>
            <Row className="justify-content-end">
              <Col xl={2} sm={4} xs={6}>
                <Form.Group className='mb-3'>
                  <Form.Label>Page Options</Form.Label>
                  <Form.Select onChange={(e) => { setPageSize(e.target.value); setPageNo(0) }}>
                    {constants?.pageOptions?.map((item, index) => (
                      <option value={item.value} key={index}>{item.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xl={2} sm={4} xs={6}>
                <Form.Group className='mb-3'>
                  <Form.Label>Status</Form.Label>
                  <Form.Select onChange={handleStatusChange}>
                    <option value="">Select Status</option>
                    {constants?.userStatus?.map((item, index) => (
                      <option value={item.value} key={index}>{item.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={4} sm={4}>
                <Form.Group className='mb-3'>
                  <Form.Label>Search</Form.Label>
                  <div className="has-search-icon">
                    <i className="fa fa-search has-icon"></i>
                    <Form.Control placeholder="Search by Username, Email" onChange={handleUserSearch} />
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Name</th>
                <th>User Name</th>
                <th>Email</th>
                <th>Withdrawal</th>
                <th>Donation</th>
                <th>Charity Supporting</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {allUsersList && allUsersList?.length ?
                <>
                  {allUsersList?.map((data, index) => (
                    <tr key={index}>
                      <td>{pageNo * pageSize + index + 1}</td>
                      <td>{capitalizeStr(data?.fullName)}</td>
                      <td>{data?.profileDetail?.username ? data?.profileDetail?.username : 'N/A'}</td>
                      <td>{data?.email}</td>
                      <td>{currencyFormat(data?.totalWithdrawn)}</td>
                      <td>{currencyFormat(data?.totalDonation)}</td>
                      <td>{data?.charityId ? capitalizeStr(data?.charityId?.title) : 'N/A'}</td>
                      <td>
                        <label className="toggle-switch">
                          <input type="checkbox" checked={data?.isActive} onChange={() => handleUserStatus(data?.isActive === true ? "block" : "unBlock", data?._id)} />
                          <div className="switch-label">
                            <span className="switch-circle"></span>
                          </div>
                        </label>
                      </td>
                      <td>
                        <ul className="action-btn-group">
                          <li>
                            <OverlayTrigger placement='top' overlay={<Tooltip>View</Tooltip>}>
                              <Link className='btn btn-icon btn-view' to={`/users/personal-details/${data?._id}`}>
                                <i className="fa-regular fa-eye"></i>
                              </Link>
                            </OverlayTrigger>
                          </li>
                          <li>
                            <OverlayTrigger placement='top' overlay={<Tooltip>Edit</Tooltip>}>
                              <Link className='btn btn-icon btn-edit' to={`/users/edit/${data?._id}`}>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </Link>
                            </OverlayTrigger>
                          </li>

                          <li>
                            <OverlayTrigger placement='top' overlay={<Tooltip>Delete</Tooltip>}>
                              <button type='button' className='btn btn-delete btn-icon' onClick={() => handleDeleteUser(data?._id)}>
                                <i className="fa-solid fa-trash"></i>
                              </button>
                            </OverlayTrigger>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  ))
                  }
                </>
                :
                <NoRecordsFound colspan={9} />
              }
            </tbody>
          </Table>
          <CommonPagination total={totalCount} pageSize={pageSize} currentPage={pageNo} onPageChange={handlePageChange} />
        </div>
      </div>
    </Layout>
  )
}

export default UserManagement