export const constants = {
  common: {
    pageNo: 0,
    pageSize: 10,
  },

  validations: {
    required: 'This field is required',
    statusInactive: 'Are you sure you want to inactivate charity?',
    statusActive: 'Are you sure you want to activate charity?',
    deleteChairty: 'Are you sure you want to delete charity?',
    logout: 'Are you sure want to logout?',
    passwordValidate: 'Password must be 8 characters, atleast one uppercase, lowercase letter and number.',
    sameValidation: 'New and confirm password should be same',
    validOtp: 'OTP must be a number',
    otpDigit: 'OTP must be 6 digits long',
    deleteUserTitle: 'Delete',
    deleteUserText: 'Are you sure you want to delete this user?',
    blockUserText: 'Are you sure you want to block this user?',
    unblockUserText: 'Are you sure you want to unblock this user?',
    deleteUserPost: 'Are you sure you want to delete this Post?',
    blockPostText: 'Are you sure you want to block this post?',
    unblockPostText: 'Are you sure you want to unblock this post?',
    maxChar1000: 'Max 1000 characters are allowed',
    maxChar100: 'Max 100 characters are allowed',
    maxDigit25: 'Max 25 digits are allowed',
    numericOnly: 'Must be a numeric value',
    numericAndHyphensOnly: 'Must be a numeric value with optional hyphens',
    deleteCommentText: 'Are you sure you want to delete this comment?',
  },

  pageOptions: [
    { value: 10, name: "10 Items per page" },
    { value: 25, name: "25 Items per page" },
    { value: 50, name: "50 Items per page" },
    { value: 100, name: "100 Items per page" },
  ],

  CharitySearchDropdown: [
    { value: "searchTerm", name: "Charity Name" },
    { value: "ein", name: "Charity EIN" }
  ],

  status: [
    { value: "active", name: "Active" },
    { value: "inactive", name: "Inactive" }
  ],

  reportStatus: [
    { value: "open", name: "Open" },
    { value: "resolved", name: "Resolved" }
  ],

  reportedFor: [
    { value: "user", name: "User" },
    { value: "post", name: "Post" },
    { value: "comment", name: "Comment" },
  ],

  decision: [
    { value: "NoAction", name: "No Action" },
    { value: "blocked", name: "Blocked" },
    { value: "unblocked", name: "Unblocked" },
  ],

  decisionComment: [
    { value: "NoAction", name: "No Action" },
    { value: "delete", name: "Delete" },
  ],

  postType: [
    { value: "PUBLIC", name: "Public" },
    { value: "EXCLUSIVE", name: "Locked" },
  ],
  userStatus: [
    { value: "true", name: "Active" },
    { value: "false", name: "Inactive" }
  ],
}
