import React from "react";
// import Header from "../header";
import LeftSideBar from "../sidebar";
import Header from "../header";

const Layout = (props) => {

  return (
    <div className='main-wrapper'>
      <Header />
      <LeftSideBar />
      <div className='content-wrapper'>
        {props.children}
      </div>
    </div>
  );
};

export default Layout;
