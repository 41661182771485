import React, { useEffect, useState } from 'react';
import { Breadcrumb, BreadcrumbItem, Col, Row, Button } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import Layout from '../../components/layout';
import { constants } from '../../helper/constants';
import { useEditReportMutation, useGetReportListQuery } from '../../Redux/api/reportApi';
import { showToast } from '../../helper/swal';
import Loader from '../../components/loader';
import { dateFormat } from '../../helper/dateFormat';
import { useDeleteCommentMutation, useUpdatePostStatusMutation } from '../../Redux/api/contentApi';
import { useUserStatusMutation } from '../../Redux/api/userApi';

const EditReport = () => {

  const initialValues = {
    createdAt: "",
    decisionStatus: "",
    fromUsername: "",
    reason: "",
    reportType: "",
    status: "",
    toUsername: "",
    updatedAt: "",
    toUserId: "",
    _id: "",
    postId: "",
    commentId: "",
  }

  const [pageNo] = useState(constants?.common?.pageNo);
  const [pageSize] = useState(constants?.common?.pageSize);
  const [formData, setFormData] = useState(initialValues);
  const { id } = useParams();
  const navigate = useNavigate();

  const { refetch: refetchReportList, data: reportListData, isFetching: isReportListFetching, isSuccess: isReportListSuccess, error: reportListError } = useGetReportListQuery({
    pageNo: pageNo + 1,
    pageSize: pageSize,
    reportId: id
  });

  const [updatePostStatus, { data: updatePostData, isLoading: isUpdatePostLoading, isSuccess: isUpdatePostSuccess, error: updatePostError }] = useUpdatePostStatusMutation();

  const [editReport, { data: editReportData, isLoading: isEditReportLoading, isSuccess: isEditReportSuccess, error: editReportError }] = useEditReportMutation();


  const [userStatus, { data: userStatusData, isLoading: isUserStatusLoading, isSuccess: isUserStatusSuccess, error: userStatusError }] = useUserStatusMutation();

  const [deleteComment, { data: deleteCommentData, isLoading: isDeleteCommentLoading, isSuccess: isDeleteCommentSuccess, error: deleteCommentError }] = useDeleteCommentMutation();

  //Form Validtaion
  const validationSchema = Yup.object().shape({
    decisionStatus: Yup.string().required(constants?.validations?.required),
    status: Yup.string().required(constants?.validations?.required),
  });

  // Update Report
  const handleSubmit = async (values) => {
    const obj = {
      reportId: id,
      action: values?.decisionStatus,
      status: values?.status,
      ...(values?.decisionStatus === "blocked" && { reportedId: formData?.toUserId })
    }
    setFormData({
      ...formData,
      decisionStatus: values?.decisionStatus
    })
    await editReport(obj);
  }

  // Report Details Response
  useEffect(() => {
    if (isReportListSuccess) {
      setFormData({
        createdAt: dateFormat(reportListData?.data?.report[0]?.createdAt),
        decisionStatus: reportListData?.data?.report[0]?.decisionStatus,
        fromUsername: reportListData?.data?.report[0]?.fromUsername,
        reason: reportListData?.data?.report[0]?.reason,
        reportType: reportListData?.data?.report[0]?.reportType,
        status: reportListData?.data?.report[0]?.status,
        toUsername: reportListData?.data?.report[0]?.toUsername,
        updatedAt: dateFormat(reportListData?.data?.report[0]?.updatedAt),
        toUserId: reportListData?.data?.report[0]?.toUserId,
        _id: reportListData?.data?.report[0]?._id,
        postId: reportListData?.data?.report[0]?.postId,
        commentId: reportListData?.data?.report[0]?.commentId
      });

    } else if (reportListError) {
      showToast(reportListError?.data?.message, "error", true)
    }
  }, [reportListData, isReportListSuccess, reportListError])



  useEffect(() => {
    // Refetch Report Detail
    refetchReportList();
  }, [refetchReportList])


  // Update Report Response
  useEffect(() => {
    if (isEditReportSuccess) {
      if (editReportData?.success && formData?.reportType === "post" && formData?.decisionStatus !== "NoAction") {
        updatePostStatus({
          data:
          {
            action: formData?.decisionStatus === "blocked" ? "inactive" : formData?.decisionStatus === "unblocked" ? "active" : "NoAction",
            postId: formData?._id
          }
        })
      }
      else if (editReportData?.success && formData?.reportType === "user" && formData?.decisionStatus !== "NoAction") {
        userStatus({ userId: formData?.toUserId })
      }
      else if (editReportData?.success && formData?.reportType === "comment" && formData?.decisionStatus !== "NoAction") {
        deleteComment(
          {
            data:
              { isDeleted: true, postId: formData?.postId }, commentId: formData?.commentId
          }
        )
      }
      else if (editReportData?.success) {
        showToast(editReportData?.message, "success", false, 2000);
        navigate("/reports");
      }
      else {
        showToast(editReportData?.message, "error", true)
      }
    } else if (editReportError) {
      showToast(editReportError?.data?.message, "error", true)
    }
  }, [isEditReportSuccess, editReportData, editReportError, isEditReportLoading, navigate, updatePostStatus, userStatus, deleteComment, formData])


  // update Post Response
  useEffect(() => {
    if (isUpdatePostSuccess) {
      if (updatePostData?.success) {
        showToast(updatePostData?.message, "success", false, 2000);
        navigate("/reports");
      } else {
        showToast(updatePostData?.message, "error", true)
      }
    } else if (updatePostError) {
      showToast(updatePostError?.data?.message, "error", true)
    }
  }, [updatePostData, isUpdatePostSuccess, updatePostError, navigate])


  // User Status Response
  useEffect(() => {
    if (isUserStatusSuccess) {
      if (userStatusData?.success) {
        showToast(userStatusData?.message, "success", false, 2000);
        navigate("/reports");
      } else {
        showToast(userStatusData?.message, "error", true)
      }
    } else if (userStatusError) {
      showToast(userStatusError?.data?.message, "error", true)
    }
  }, [userStatusData, isUserStatusSuccess, userStatusError, navigate])


  // Delete Comment API Response
  useEffect(() => {
    if (isDeleteCommentSuccess) {
      if (deleteCommentData?.success) {
        showToast(deleteCommentData?.message, "success", false, 1000);
        navigate("/reports");
      } else {
        showToast(deleteCommentData?.message, "error", true)
      }
    } else if (deleteCommentError) {
      showToast(deleteCommentError?.data?.message, "error", true)
    }
  }, [isDeleteCommentSuccess, deleteCommentData, deleteCommentError, navigate])


  return (
    <Layout>
      {(isReportListFetching || isEditReportLoading || isUpdatePostLoading || isUserStatusLoading || isDeleteCommentLoading) ? <Loader /> : null}
      <div className='conent-header'>
        <h1 className='title-h1'>Edit Report</h1>
        <Breadcrumb>
          <BreadcrumbItem linkAs="span">
            <Link to="/reports">Reporting</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Edit Report</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <Formik initialValues={formData} enableReinitialize validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className='card'>
                <div className='card-body'>
                  <Row>
                    <Col md={4} sm={6}>
                      <div className="mb-3">
                        <label className='form-label'>Reported Username</label>
                        <Field type="text" className="form-control" name="toUsername" disabled />
                      </div>
                    </Col>
                    <Col md={4} sm={6}>
                      <div className="mb-3">
                        <label className='form-label'>Reported For</label>
                        <Field type="text" className="form-control" name="reportType" disabled />
                      </div>
                    </Col>
                    <Col md={4} sm={6}>
                      <div className="mb-3">
                        <label className='form-label'>Reported On</label>
                        <Field type="text" className="form-control" name="createdAt" disabled />
                      </div>
                    </Col>
                    <Col md={4} sm={6}>
                      <div className="mb-3">
                        <label className='form-label'>Reporting Username</label>
                        <Field type="text" className="form-control" name="fromUsername" disabled />
                      </div>
                    </Col>
                    <Col md={4} sm={6}>
                      <div className="mb-3">
                        <label className='form-label'>Reported Reason</label>
                        <Field type="text" className="form-control" name="reason" disabled />
                      </div>
                    </Col>
                    <Col md={4} sm={6}>
                      <div className="mb-3">
                        <label className='form-label'>Report Updated On</label>
                        <Field type="text" className="form-control" name="updatedAt" disabled />
                      </div>
                    </Col>
                    <Col md={4} sm={6}>
                      <div className="mb-3">
                        <label className='form-label'>Decision</label>
                        <Field as="select" name="decisionStatus" className="form-control">
                          <option value="">Select Decision</option>
                          {formData?.reportType === "comment" ?
                            <>
                              {constants?.decisionComment?.map((item, index) => (
                                <option value={item.value} key={index}>{item.name}</option>
                              ))}
                            </> :
                            <>
                              {constants?.decision?.map((item, index) => (
                                <option value={item.value} key={index}>{item.name}</option>
                              ))}
                            </>
                          }
                        </Field>
                        <ErrorMessage name="decisionStatus" component="div" className="error-text" />
                      </div>
                    </Col>
                    <Col md={4} sm={6}>
                      <div className="mb-3">
                        <label className='form-label'>Status</label>
                        <Field as="select" name="status" className="form-control">
                          <option value="">Select Status</option>
                          {constants?.reportStatus?.map((item, index) => (
                            <option value={item.value} key={index}>{item.name}</option>
                          ))}
                        </Field>
                        <ErrorMessage name="status" component="div" className="error-text" />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className='text-center mt-4'>
                <Button type="submit" variant="primary" className='btn-size-xs'>Save</Button>
                <Link to="/reports" className='btn btn-secondary btn-size-xs ms-2'>Cancel</Link>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Layout>
  )
}

export default EditReport