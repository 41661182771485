import React from 'react'

const NoRecordsFound = (props) => {
  return (
    <tr>
      <td colSpan={props.colspan}>
        <p className='text-center mb-0'>No Record Found</p>
      </td>
    </tr>
  );
}

export default NoRecordsFound

