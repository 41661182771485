
import { createApi} from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../../utils/baseQuery";

// const baseUrl = process.env.REACT_APP_API_URL;

export const paymentApi = createApi({
  reducerPath: "paymentApi",
  baseQuery: baseQueryWithReauth,

  endpoints: (builder) => ({
    getSubscriptionsList: builder.query({
      query: (data) => {
        const { pageNo, pageSize, subscriberStatus, searchParam, fromDate, toDate } = data;
        const url = `pageNo=${pageNo}&pageSize=${pageSize}${subscriberStatus ? `&status=${subscriberStatus}` : ''}${fromDate ? `&fromDate=${fromDate}` : ''}${toDate ? `&toDate=${toDate}` : ''}${searchParam ? `&search=${searchParam}` : ''}`
        return {
          url: `/payment/v1/subscriptions-list?${url}`,
        }
      }
    }),
    getDonationsList: builder.query({
      query: (data) => {
        const { pageNo, pageSize, searchCharity, searchUsername, fromDate, toDate } = data;
        const url = `pageNo=${pageNo}&pageSize=${pageSize}${searchCharity ? `&searchCharity=${searchCharity}` : ''}${fromDate ? `&fromDate=${fromDate}` : ''}${toDate ? `&toDate=${toDate}` : ''}${searchUsername ? `&searchUsername=${searchUsername}` : ''}`
        return {
          url: `/payment/v1/donations-list?${url}`
        }
      }
    }),
    getPayoutsList: builder.query({
      query: (data) => {
        const { pageNo, pageSize, searchUsername, fromDate, toDate } = data;
        const url = `pageNo=${pageNo}&pageSize=${pageSize}${fromDate ? `&fromDate=${fromDate}` : ''}${toDate ? `&toDate=${toDate}` : ''}${searchUsername ? `&searchUsername=${searchUsername}` : ''}`
        return {
          url: `/payment/v1/payouts-list?${url}`
        }
      }
    }),
    getPayoutDetail: builder.query({
      query: (data) => {
        const { payoutId } = data;
        const url = payoutId;
        return {
          url: `/payment/v1/payout-list/${url}`
        }
      }
    }),
    getUserEarningDetail: builder.query({
      query: (data) => {
        const { userId } = data;
        const url = userId;
        return {
          url: `/payment/v1/user-earnings/${url}`
        }
      }
    }),
    getDonationHistory: builder.query({
      query: (data) => {
        const { pageNo, pageSize,userId} = data;
        const url = `${userId}?pageNo=${pageNo}&pageSize=${pageSize}`
        return {
          url: `/payment/v1/user-donation-history/${url}`
        }
      }
    }),
    getWithdrawalHistory: builder.query({
      query: (data) => {
        const { pageNo, pageSize,userId} = data;
        const url = `${userId}?pageNo=${pageNo}&pageSize=${pageSize}`
        return {
          url: `/payment/v1/user-withdraw-history/${url}`
        }
      }
    }),
  }),
});

export const {
  useGetSubscriptionsListQuery,
  useGetDonationsListQuery,
  useGetPayoutsListQuery,
  useGetPayoutDetailQuery,
  useGetUserEarningDetailQuery,
  useGetDonationHistoryQuery,
  useGetWithdrawalHistoryQuery
} = paymentApi;