
import { createApi} from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../../utils/baseQuery";

export const charityApi = createApi({
  reducerPath: "charityApi",
  baseQuery: baseQueryWithReauth,

  endpoints: (builder) => ({
    getOrgCharityList: builder.query({
      query: (data) => {
        const { searchParam, selectParam } = data;
        const url = selectParam === "searchTerm" ? `searchTerm=${searchParam}` : `ein=${searchParam}`;
        return {
          url: `/charity/v1/orghunter-charity-search?${url}`
        }
      }
    }),
    addCharity: builder.mutation({
      query: (body) => {
        return {
          url: '/charity/v1/create',
          method: 'POST',
          headers: {},
          body,
        }
      },
    }),
    getCharityList: builder.query({
      query: (data) => {
        const { pageNo, pageSize, searchParam, status } = data;
        const url = `pageNo=${pageNo}&pageSize=${pageSize}${searchParam ? `&searchText=${searchParam}` : ''}${status ? `&status=${status}` : ''}`
        return {
          url: `/charity/v1/charity-search?${url}`
        }
      }
    }),
    getCharityDetail: builder.query({
      query: (data) => {
        const { charityId } = data;
        const url = charityId;
        return {
          url: `/charity/v1/charity/${url}`
        }
      }
    }),
    editCharity: builder.mutation({
      query: (body) => {
        const { data, updateCharityId } = body;
        return {
          url: `charity/v1/update/${updateCharityId}`,
          method: 'PUT',
          headers: {},
          body: data
        }
      },
    }),
  }),
});

export const {
  useGetOrgCharityListQuery,
  useAddCharityMutation,
  useGetCharityListQuery,
  useGetCharityDetailQuery,
  useEditCharityMutation,
} = charityApi;