import React, { useEffect, useState } from 'react';
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Layout from '../../components/layout';
import { constants } from '../../helper/constants';
import { useGetReportListQuery } from '../../Redux/api/reportApi';
import { showToast } from '../../helper/swal';
import { dateFormat } from '../../helper/dateFormat';
import Loader from '../../components/loader';
import { capitalizeStr } from '../../helper/capitalizeStr';

const ReportDetail = () => {

  const [pageNo] = useState(constants?.common?.pageNo);
  const [pageSize] = useState(constants?.common?.pageSize);
  const { id } = useParams();
  const [reportData, setReportData] = useState({
    createdAt: "",
    decisionStatus: "",
    fromUsername: "",
    reason: "",
    reportType: "",
    status: "",
    toUsername: "",
    updatedAt: "",
    toUserId: "",
    fromUserId: "",
    commentId: ""
  })

  const { refetch: refetchReportList, data: reportListData, isFetching: isReportListFetching, isSuccess: isReportListSuccess, error: reportListError } = useGetReportListQuery({
    pageNo: pageNo + 1,
    pageSize: pageSize,
    reportId: id
  });

  useEffect(() => {
    // Refetch Report Detail
    refetchReportList();
  }, [refetchReportList])

  // Report List Response
  useEffect(() => {
    if (isReportListSuccess) {
      setReportData({
        createdAt: reportListData?.data?.report[0]?.createdAt,
        decisionStatus: reportListData?.data?.report[0]?.decisionStatus,
        fromUsername: reportListData?.data?.report[0]?.fromUsername,
        reason: reportListData?.data?.report[0]?.reason,
        reportType: reportListData?.data?.report[0]?.reportType,
        status: reportListData?.data?.report[0]?.status,
        toUsername: reportListData?.data?.report[0]?.toUsername,
        updatedAt: reportListData?.data?.report[0]?.updatedAt,
        toUserId: reportListData?.data?.report[0]?.toUserId,
        fromUserId: reportListData?.data?.report[0]?.fromUserId,
        commentId: reportListData?.data?.report[0]?.commentId,
      });

    } else if (reportListError) {
      showToast(reportListError?.data?.message, "error", true)
    }
  }, [reportListData, isReportListSuccess, reportListError])


  return (
    <Layout>
      {isReportListFetching ? <Loader /> : null}
      <div className='conent-header'>
        <h1 className='title-h1'>Reporting Details</h1>
        <Breadcrumb>
          <BreadcrumbItem linkAs="span">
            <Link to="/reports">Reporting</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Reporting Details</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div className='card'>
        <div className='card-body'>
          <Row>
            <Col md={4} xs={6}>
              <div className="mb-3">
                <label className='form-label'>Reported Username</label>
                <div>
                  <Link className='mb-0 text-break' to={`/users/personal-details/${reportData?.toUserId}`}>{reportData?.toUsername}</Link>
                </div>
              </div>
            </Col>
            <Col md={4} xs={6}>
              <div className="mb-3">
                <label className='form-label'>Reported For</label>
                {reportData?.reportType === "comment" ?
                  <div>
                    <Link className='mb-0' to={`/content/detail/comments/detail/${reportData?.commentId}`}>{capitalizeStr(reportData?.reportType)}</Link>
                  </div>
                  :
                  <p className='mb-0'>{capitalizeStr(reportData?.reportType)}</p>
                }
              </div>
            </Col>
            <Col md={4} xs={6}>
              <div className="mb-3">
                <label className='form-label'>Reported On</label>
                <p className='mb-0'>{dateFormat(reportData?.createdAt)}</p>
              </div>
            </Col>
            <Col md={4} xs={6}>
              <div className="mb-3">
                <label className='form-label'>Reporting Username</label>
                <div>
                  <Link className='mb-0 text-break' to={`/users/personal-details/${reportData?.fromUserId}`}>{reportData?.fromUsername}</Link>
                </div>
              </div>
            </Col>
            <Col md={4} xs={6}>
              <div className="mb-3">
                <label className='form-label'>Reported Reason</label>
                <p className='mb-0'>{capitalizeStr(reportData?.reason)}</p>
              </div>
            </Col>
            <Col md={4} xs={6}>
              <div className="mb-3">
                <label className='form-label'>Report Updated On</label>
                <p className='mb-0'>{dateFormat(reportData?.updatedAt)}</p>
              </div>
            </Col>
            <Col md={4} xs={6}>
              <div className="mb-3">
                <label className='form-label'>Decision</label>
                <p className='mb-0'>{capitalizeStr(reportData?.decisionStatus)}</p>
              </div>
            </Col>
            <Col md={4} xs={6}>
              <div className="mb-3">
                <label className='form-label'>Status</label>
                <p className='mb-0'>{capitalizeStr(reportData?.status)}</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className='text-center mt-4'>
        <Link to={`/reports/edit/${id}`} className='btn btn-primary btn-size-xs'>Edit</Link>
        <Link to="/reports" className='btn btn-secondary btn-size-xs ms-2'>Cancel</Link>
      </div>
    </Layout>
  )
}

export default ReportDetail