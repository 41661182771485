import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import Layout from '../../../components/layout'
import Loader from '../../../components/loader';
import { constants } from '../../../helper/constants';
import NoRecordsFound from '../../../components/shared/NoRecordsFound';
import CommonPagination from '../../../components/shared/CommonPagination';
import { useGetDonationsListQuery } from '../../../Redux/api/paymentApi';
import { debounce } from 'lodash';
import { showToast } from '../../../helper/swal';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { dateFormat } from '../../../helper/dateFormat';
import { currencyFormat, numberFormatStyle } from '../../../helper/numberFormat';
import { capitalizeStr } from '../../../helper/capitalizeStr';

const Donations = () => {
  const [pageNo, setPageNo] = useState(constants?.common?.pageNo);
  const [pageSize, setPageSize] = useState(constants?.common?.pageSize);
  const [totalCount, setTotalCount] = useState(0);
  const [searchUsername, setSearchUsername] = useState("");
  const [searchCharity, setSearchCharity] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState("");
  const [donationList, setDonationList] = useState([]);

  const { refetch: refetchDonationsList, data: donationsListData, isFetching: isDonationsListFetching, isSuccess: isDonationsListSuccess, error: donationsListError } = useGetDonationsListQuery({
    pageNo: pageNo + 1,
    pageSize: pageSize,
    searchUsername: searchUsername,
    searchCharity: searchCharity,
    fromDate: fromDate,
    toDate: toDate
  });

  // Page Change
  const handlePageChange = (page) => {
    const selectedPageNo = setPageNo(page.selected);
    return selectedPageNo;
  }

  // Search Username
  const handleUsernameSearch = debounce((e) => {
    setSearchUsername(e.target.value);
    setPageNo(0);
  }, 500)

  // Search Charity
  const handleCharitySearch = debounce((e) => {
    setSearchCharity(e.target.value);
    setPageNo(0);
  }, 500)

  // From Date
  const handleFromDateChange = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    setFromDate(formattedDate);
    setPageNo(0);
  }

  // To Date
  const handleToDateChange = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    setToDate(formattedDate);
    setPageNo(0);
  }

  useEffect(() => {
    // Refetch Donations list on page change
    refetchDonationsList();
  }, [pageNo, pageSize, searchCharity, searchUsername, refetchDonationsList])


  // Donations List Response
  useEffect(() => {
    if (isDonationsListSuccess) {
      setDonationList(donationsListData?.data?.donationsList);
      setTotalCount(donationsListData?.data?.totalCount)
    } else if (donationsListError) {
      showToast(donationsListError?.data?.message, "error", true)
    }
  }, [donationsListData, isDonationsListSuccess, donationsListError])


  return (
    <Layout>
      {(isDonationsListFetching) && <Loader />}
      <div className='conent-header'>
        <h1 className='title-h1'>Donations</h1>
      </div>
      <div className='card'>
        <div className='card-body'>
          <div className='card-filter'>
            <Row className="justify-content-end">
              <Col lg={2} sm={4}>
                <Form.Group className='mb-3'>
                  <Form.Label>Page Options</Form.Label>
                  <Form.Select onChange={(e) => { setPageSize(e.target.value); setPageNo(0) }}>
                    {constants?.pageOptions?.map((item, index) => (
                      <option value={item.value} key={index}>{item.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={2} sm={4} xs={6}>
                <Form.Group className='mb-3'>
                  <Form.Label>From Date</Form.Label>
                  <div className="has-search-icon">
                    <i className="fa fa-search has-icon"></i>
                    <DatePicker
                      className="form-control"
                      selected={fromDate ? moment(fromDate).toDate() : null}
                      placeholderText="From Date"
                      onChange={handleFromDateChange}
                      dateFormat="yyyy-MM-dd"
                      maxDate={toDate ? moment(toDate).toDate() : null}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg={2}  sm={4} xs={6}>
                <Form.Group className='mb-3'>
                  <Form.Label>To Date</Form.Label>
                  <div className="has-search-icon">
                    <i className="fa fa-search has-icon"></i>
                    <DatePicker
                      className="form-control"
                      selected={toDate ? moment(toDate).toDate() : null}
                      minDate={fromDate ? moment(fromDate).toDate() : null}
                      placeholderText="To Date"
                      onChange={handleToDateChange}
                      dateFormat="yyyy-MM-dd"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      disabled={!fromDate && true}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col xl={2} lg={3} xs={6}>
                <Form.Group className='mb-3'>
                  <Form.Label>Search Username</Form.Label>
                  <div className="has-search-icon">
                    <i className="fa fa-search has-icon"></i>
                    <Form.Control placeholder="Search by username" onChange={handleUsernameSearch} />
                  </div>
                </Form.Group>
              </Col>
              <Col xl={2} lg={3} xs={6}>
                <Form.Group className='mb-3'>
                  <Form.Label>Search Charity</Form.Label>
                  <div className="has-search-icon">
                    <i className="fa fa-search has-icon"></i>
                    <Form.Control placeholder="Search by charity" onChange={handleCharitySearch} />
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Username</th>
                <th>Charity</th>
                <th>Coins</th>
                <th>Amount</th>
                <th>Date</th>
                {/* <th>Receipt</th> */}
              </tr>
            </thead>
            <tbody>
              {donationList && donationList?.length ?
                <>
                  {donationList?.map((data, index) => (
                    <tr key={index}>
                      <td>{pageNo * pageSize + index + 1}</td>
                      <td>{data?.username}</td>
                      <td>{capitalizeStr(data?.charityTitle)}</td>
                      <td>{numberFormatStyle(data?.coins)}</td>
                      <td>{currencyFormat(data?.donationAmount)}</td>
                      <td>{dateFormat(data?.donationDate)}</td>
                      {/* <td>{data?.receipt}</td> */}
                    </tr>
                  ))
                  }
                </>
                :
                <NoRecordsFound colspan={6} />
              }
            </tbody>
          </Table>
          <CommonPagination total={totalCount} pageSize={pageSize} currentPage={pageNo} onPageChange={handlePageChange} />
        </div>
      </div>
    </Layout>
  )
}

export default Donations