import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { clearAuth, logoutUser } from '../../Redux/slice/authSlice';
import Swal from 'sweetalert2';
import { constants } from '../../helper/constants';
import Images from '../Images';
import { useState } from 'react';

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleMenu = () => {
    setIsSidebarOpen(!isSidebarOpen);

    // Toggle body class
    document.body.classList.toggle('sidebar-toggle', !isSidebarOpen);
  };

  const hideSidebar = () => {
    setIsSidebarOpen(false);
    // Remove body class
    document.body.classList.remove('sidebar-toggle');
  };

  const handleLogout = () => {
    Swal.fire({
      title: "Logout",
      text: constants?.validations?.logout,
      icon: 'question',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value) {
          dispatch(clearAuth());
          dispatch(logoutUser());
          navigate('/');
        }
      }
    })
  }

  useState(() => {
    if (!isSidebarOpen) {
      document.body.classList.remove('sidebar-toggle');
    }
  },[isSidebarOpen])
  return (
    <>
      <header className="main-header d-flex">
        <nav className="navbar navbar-expand flex-one">
          <div className="burger-menu" role="menu" onClick={toggleMenu}>
            <i className="fa-solid fa-bars"></i>
          </div>
          <div className="collapse navbar-collapse">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Dropdown className='custom-dropdown'>
                  <Dropdown.Toggle as="button" className='nav-link d-flex align-items-center'>
                    <div className="profile-pic me-2">
                      <img src={Images.USER_PLACEHOLDER} alt="Profile Pic" width="25" />
                    </div>
                    Admin
                  </Dropdown.Toggle>
                  <Dropdown.Menu as="ul" >
                    {/* <li><Link className='dropdown-item'>Profile</Link></li> */}
                    <li><Link to="/change-password" className={window.location.pathname.includes("change-password") ? "dropdown-item active" : "dropdown-item"}>Change Password</Link></li>
                    <li> <Dropdown.Item as="button" onClick={() => handleLogout(true)}>Logout</Dropdown.Item></li>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
            <div className="burger-menu ml-3 burger-menu-mobile" role="menu">
              <div className="burger"></div>
            </div>
          </div>
        </nav>
      </header>
      {isSidebarOpen && <div className="sidebar-overlay" onClick={hideSidebar}></div>}
    </>

  )
}

export default Header