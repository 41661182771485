import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Images from '../../components/Images';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { constants } from '../../helper/constants';
import { useSelector } from 'react-redux';
import { encrypt } from '../../helper/crypto';
import { useResetPasswordMutation } from '../../Redux/api/authApi';
import { showToast } from '../../helper/swal';
import { Link, useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const navigate = useNavigate();
  const verifyToken = useSelector((state) => state.verifyOtp.forgotToken);
  const [resetPassword, { data: resetPasswordData, isLoading: isResetPasswordLoading, isSuccess: isResetPasswordSuccess, error: resetPasswordError }] = useResetPasswordMutation();

  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  }

  // Reset Password Form Validation Schema
  const resetPasswordSchema = Yup.object().shape({
    newPassword: Yup.string().required(constants?.validations?.required)
      .matches(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
        "Password must contain at least 8 characters with 1 uppercase, 1 lowercase, 1 numeric, and 1 special character"
      ),
    confirmPassword: Yup.string().required(constants?.validations?.required).oneOf([Yup.ref("newPassword")], "The New password and confirmation password must match."),
  });


  // Handle Submit
  const handleSubmit = async (values) => {
    const obj = {
      password: encrypt(values.newPassword),
      verificationToken: verifyToken,
    }
    await resetPassword(obj);
  }

  // Reset Password Response
  useEffect(() => {
    if (isResetPasswordSuccess) {
      showToast(resetPasswordData?.message, "success", false, 3000);
      navigate("/");
    } else if (resetPasswordError) {
      showToast(resetPasswordError?.data?.message, "error", true)
    }
  }, [isResetPasswordSuccess, resetPasswordError, resetPasswordData?.message, isResetPasswordLoading, navigate]);

  const PasswordField = ({ name }) => {
    // password toggle eye
    const [isVisible, setVisible] = useState(false);
    const togglePassword = () => {
      setVisible(!isVisible);
    }
    return (
      <div className="form-pass">
        <Field type={isVisible ? 'text' : 'password'} name={name} className="form-control" autoComplete="off"></Field>
        <button type="button" className='btn' onClick={() => togglePassword()}>
          <i className={isVisible ? "fa-solid fa-eye" : "fa-solid fa-eye-slash"}></i>
        </button>
      </div>
    )
  }
  return (
    <div className="auth-page-wrapper">
      <Container>
        <div className="auth-box">
          <div className="text-center mb-4">
            <img src={Images.LOGO} className="header-logo" alt="Brand Logo" />
          </div>
          <div className="auth-card">
            <div className="auth-card-body">
              <div className="text-center mb-4">
                <h1>Reset Password</h1>
              </div>
              <Formik initialValues={initialValues} validationSchema={resetPasswordSchema} onSubmit={handleSubmit}>
                {({ handleSubmit }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label className="form-label">New Password</label>
                        <PasswordField name="newPassword" />
                        <ErrorMessage name="newPassword" component="div" className="error-text" />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Confirm New Password</label>
                        <PasswordField name="confirmPassword" />
                        <ErrorMessage name="confirmPassword" component="div" className="error-text" />
                      </div>
                      <div className="mt-4 mb-3">
                        <button type="submit" className={isResetPasswordLoading ? "btn btn-primary w-100 pe-none" : "btn btn-primary w-100"}>
                          {isResetPasswordLoading ?
                            <>
                              <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                              Loading...
                            </> :
                            <span>Submit</span>
                          }
                        </button>
                      </div>
                      <div className="mb-1">
                        Have an account?<Link to="/" className="ms-1">Login</Link>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default ResetPassword