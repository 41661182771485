
import { createApi} from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../../utils/baseQuery";

export const profileApi = createApi({
  reducerPath: "profileApi",
  baseQuery: baseQueryWithReauth,
  
  endpoints: (builder) => ({
    changePassword: builder.mutation({
      query: (body) => {
        return {
          url: '/user/v1/change-password',
          method: 'POST',
          headers: {},
          body,
        }
      },
    }),
    getUserInfo: builder.query({
      query: (data) => {
        const { userId, tabType, pageNo, pageSize } = data;
        const url = `${userId}?tabType=${tabType}${pageNo ? `&pageNo=${pageNo}` : ''}${pageSize ? `&pageSize=${pageSize}` : ''}`
        return {
          url: `/user/v1/personal-details/${url}`
        }
      }
    }),
  }),
});

export const {
  useChangePasswordMutation,
  useGetUserInfoQuery
} = profileApi;