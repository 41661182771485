import React, { useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { showToast } from '../../helper/swal';
import { useGetUserInfoQuery } from '../../Redux/api/profileApi';
import { constants } from '../../helper/constants';
import { useParams } from 'react-router-dom';
import { removeSlash } from '../../helper/removeSlash';
import Images from '../Images';

const SubscribersTabData = () => {
  const { id } = useParams();
  const [pageNo, setPageNo] = useState(1);
  const [pageSize] = useState(constants?.common?.pageSize);
  const [hasMoreSubscribers, setHasMoreSubscribers] = useState(false)
  const [subscribersList, setSubscribersList] = useState([]);
  const PageNoRef = useRef(pageNo);

  const { data: subscribersData, isFetching: isSubscribersDataFetching, error: subscribersListError } = useGetUserInfoQuery({
    userId: id,
    tabType: 3,
    pageNo: pageNo,
    pageSize: pageSize
  });

  // Subscribers List Response
  useEffect(() => {
    const handleSubscribersListResponse = () => {
      if (PageNoRef === 1) {
        setSubscribersList(subscribersData?.data?.data);
      } else {
        let nextSubscribersList = subscribersData?.data?.data;
        setSubscribersList((prevSubscribersList) => [...prevSubscribersList, ...nextSubscribersList]);
      }
    };
    if (subscribersData) {
      handleSubscribersListResponse();
      setHasMoreSubscribers(subscribersData?.data?.data?.length === 10 ? true : false)
    } else if (subscribersListError) {
      showToast(subscribersListError?.data?.message, "error", true);
    }
  }, [subscribersData, subscribersListError])


  const fetchData = () => {
    setPageNo(pageNo + 1);
  }
  return (
    <div id="scrollablescrll" className='scroll-content'>
      <InfiniteScroll
        dataLength={subscribersList?.length}
        next={fetchData}
        hasMore={hasMoreSubscribers}
        loader={<p className='text-center'>Loading...</p>}
        scrollableTarget="scrollablescrll"
      >
        {(subscribersList && subscribersList?.length) ?
          <ul className='user-list'>
            {subscribersList?.map((data, index) => (
              <li key={index}>
                <div className='user-left-col'>
                  <div className='user-thumb'>
                    <img src={data?.profileDetail?.profilePic ? process.env.REACT_APP_BASE_IMG_URL + removeSlash(data?.profileDetail?.profilePic) : Images?.PLACEHOLDER_IMG} alt='' />
                  </div>
                </div>
                <div className='user-right-col'>
                  <p>{data?.fullName}</p>
                </div>
              </li>
            ))}
          </ul>
          :
          <>
            {isSubscribersDataFetching ?
              <p className='text-center'>Loading...</p>
              :
              <p className='text-center'>No Record Found</p>
            }
          </>
        }
      </InfiniteScroll>
    </div>
  )
}

export default SubscribersTabData