import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import { Breadcrumb, BreadcrumbItem, Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAddCharityMutation, useGetOrgCharityListQuery } from '../../Redux/api/charityApi';
import { showToast } from '../../helper/swal';
import Loader from '../../components/loader';
import { debounce } from 'lodash';
import { capitalizeStr } from '../../helper/capitalizeStr';
import { constants } from '../../helper/constants';

const SearchCharity = () => {
  const [searchText, setSearchText] = useState(null);
  const [orgCharityList, setOrgCharityList] = useState([]);
  const [selectText, setSelectText] = useState("searchTerm");
  const [addCharity, { data: addCharityData, isLoading: isCharityLoading, isSuccess: isCharitySuccess, error: charityError }] = useAddCharityMutation();

  const { data: OrgCharityListData, isFetching: OrgCharitListLoading, isSuccess: OrgCharitListSuccess, error: OrgCharitListError } = useGetOrgCharityListQuery({
    searchParam: searchText,
    selectParam: selectText
  }, {
    skip: searchText == null // Set skip option to true to prevent automatic execution
  });

  // Get org Charity Listing
  const handleOrgCharitySearch = debounce((event) => {
    setSearchText(event.target.value);

    if (!event.target.value.length) {
      setOrgCharityList([]);
      setSearchText(null);
    }
  }, 500)

  //Change Select Charity value
  const handleSearchCharityChange = (e) => {
    setSelectText(e.target.value);
  }

  useEffect(() => {
    if (OrgCharitListSuccess) {
      setOrgCharityList(OrgCharityListData?.data);

    } else if (OrgCharitListError) {
      showToast(OrgCharitListError?.data?.message, "error", true)
    }
  }, [OrgCharityListData, OrgCharitListSuccess, OrgCharitListError]);


  useEffect(() => {
    if (OrgCharitListSuccess) {
      setOrgCharityList(OrgCharityListData?.data);

    } else if (OrgCharitListError) {
      showToast(OrgCharitListError?.data?.message, "error", true)
    }
  }, [OrgCharityListData, OrgCharitListSuccess, OrgCharitListError]);


  // Add org Charity 
  const handleAddCharity = async (data, e) => {
    e.stopPropagation();
    const obj = {
      acceptingDonations: data?.ein,
      category: data?.category,
      title: data?.charityName,
      city: data?.city,
      deductibilityCd: data?.deductibilityCd,
      donationUrl: data?.donationUrl,
      ein: data?.ein,
      eligibleCd: data?.eligibleCd,
      latitude: data?.latitude,
      longitude: data?.longitude,
      parent_ein: data?.parent_ein,
      state: data?.state,
      statusCd: data?.statusCd,
      url: data?.url,
      zipCode: data?.zipCode,
      description: data?.category,
      status: "active"
    }
    await addCharity(obj);
  }

  useEffect(() => {
    if (isCharitySuccess) {
      if (addCharityData?.success) {
        showToast(addCharityData?.message, "success", false, 3000);
      } else {
        showToast(addCharityData?.message, "error", true)
      }
    } else if (charityError) {
      showToast(charityError?.data?.message, "error", true)
    }
  }, [addCharityData, isCharitySuccess, charityError]);

  return (
    <Layout>
      {OrgCharitListLoading || isCharityLoading ? <Loader /> : null}
      <div className='conent-header'>
        <h1 className='title-h1'>Search Charity</h1>
        <Breadcrumb>
          <BreadcrumbItem linkAs="span">
            <Link to="/charity">All Charities</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Search Results</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <Row className="justify-content-sm-center">
        <Col xs sm={9} lg={8} xl={6}>
          <div className='page-search'>
            <InputGroup>
              <Form.Select onChange={handleSearchCharityChange}>
                {constants?.CharitySearchDropdown?.map((item, index) => (
                  <option value={item.value} key={index}>{item.name}</option>
                ))}
              </Form.Select>
              <div className="has-search-icon flex-fill">
                <i className="fa fa-search has-icon"></i>
                <Form.Control placeholder="Search By Name, EIN" onChange={handleOrgCharitySearch} />
              </div>
            </InputGroup>
            {/* <Button variant="primary">Search</Button> */}
          </div>
        </Col>
      </Row>
      <div className='mt-4'>
        <Row className="justify-content-xl-center">
          <Col xl={8}>
            {(orgCharityList && orgCharityList?.length) ?
              <>
                <h4>Search Results</h4>
                <div className='card'>
                  <div className='card-body charity-scroller'>
                    <ul className='charity-list'>
                      {orgCharityList?.map((data, index) => (
                        <li key={index}>
                          <div>
                            <h5>{capitalizeStr(data?.charityName)}</h5>
                            <p>
                              {capitalizeStr(data?.city)}
                              {data?.city && ', '}
                              {data?.state}
                              {data?.zipCode}
                            </p>
                            <p><strong>EIN:</strong>{data?.ein}</p>
                            <p><strong>Category:</strong>{data?.category}</p>
                          </div>
                          <Button variant='outline-primary' onClick={(e) => handleAddCharity(data, e)}>Add Charity</Button>
                        </li>
                      ))
                      }
                    </ul>
                  </div>
                </div>
              </>
              :
              null
            }
            {(OrgCharitListSuccess && !orgCharityList?.length) &&
              <>
                <h4>Search Results</h4>
                <div className='card'>
                  <div className='card-body'>
                    <h5 className='text-center'>No Record Found</h5>
                  </div>
                </div>
              </>
            }
          </Col>
        </Row>
      </div>
    </Layout >
  )
}

export default SearchCharity