import React, { useEffect, useState } from 'react';
import Layout from '../../../components/layout';
import { Col, Form, Row, Table } from 'react-bootstrap';
import { constants } from '../../../helper/constants';
import NoRecordsFound from '../../../components/shared/NoRecordsFound';
import CommonPagination from '../../../components/shared/CommonPagination';
import { useGetSubscriptionsListQuery } from '../../../Redux/api/paymentApi';
import { showToast } from '../../../helper/swal';
import { dateFormat } from '../../../helper/dateFormat';
import Loader from '../../../components/loader';
import { debounce } from 'lodash';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { currencyFormat, numberFormatStyle } from '../../../helper/numberFormat';

const Subscriptions = () => {
    const [pageNo, setPageNo] = useState(constants?.common?.pageNo);
    const [pageSize, setPageSize] = useState(constants?.common?.pageSize);
    const [totalCount, setTotalCount] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [status, setStatus] = useState("");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState("");
    const [subscribersList, setSubscribersList] = useState([]);

    const { refetch: refetchSubscribersList, data: subscribersListData, isFetching: isSubscribersListFetching, isSuccess: isSubscribersListSuccess, error: subscribersListError } = useGetSubscriptionsListQuery({
        pageNo: pageNo + 1,
        pageSize: pageSize,
        subscriberStatus: status,
        searchParam: searchText,
        fromDate: fromDate,
        toDate: toDate
    });

    // Page Change
    const handlePageChange = (page) => {
        const selectedPageNo = setPageNo(page.selected);
        return selectedPageNo;
    }

    // Search Subscribers
    const handleSubscribersSearch = debounce((e) => {
        setSearchText(e.target.value);
        setPageNo(0);
    }, 500)

    //Get Subscribers Status
    const handleStatusChange = (e) => {
        setStatus(e.target.value);
        setPageNo(0);
    }

    // From Date
    const handleFromDateChange = (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        setFromDate(formattedDate);
        setPageNo(0);
    }

    // To Date
    const handleToDateChange = (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        setToDate(formattedDate);
        setPageNo(0);
    }


    useEffect(() => {
        // Refetch Subscribers list on page change
        refetchSubscribersList();
    }, [pageNo, pageSize, status, searchText, refetchSubscribersList])


    // Subscribers List Response
    useEffect(() => {
        if (isSubscribersListSuccess) {
            setSubscribersList(subscribersListData?.data?.subscriptionList);
            setTotalCount(subscribersListData?.data?.totalCount)
        } else if (subscribersListError) {
            showToast(subscribersListError?.data?.message, "error", true)
        }
    }, [subscribersListData, isSubscribersListSuccess, subscribersListError])

    return (
        <Layout>
            {(isSubscribersListFetching) && <Loader />}
            <div className='conent-header'>
                <h1 className='title-h1'>Subscriptions</h1>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <div className='card-filter'>
                        <Row className="justify-content-end">
                            <Col lg={2} xs={6}>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Page Options</Form.Label>
                                    <Form.Select onChange={(e) => { setPageSize(e.target.value); setPageNo(0) }}>
                                        {constants?.pageOptions?.map((item, index) => (
                                            <option value={item.value} key={index}>{item.name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col lg={2} xs={6} >
                                <Form.Group className='mb-3'>
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select onChange={handleStatusChange}>
                                        <option value="">Select Status</option>
                                        {constants?.userStatus?.map((item, index) => (
                                            <option value={item.value} key={index}>{item.name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col lg={2} sm={4} xs={6}>
                                <Form.Group className='mb-3'>
                                    <Form.Label>From Date</Form.Label>
                                    <div className="has-search-icon">
                                        <i className="fa fa-search has-icon"></i>
                                        <DatePicker
                                            className="form-control"
                                            selected={fromDate ? moment(fromDate).toDate() : null}
                                            placeholderText="From Date"
                                            onChange={handleFromDateChange}
                                            dateFormat="yyyy-MM-dd"
                                            maxDate={toDate ? moment(toDate).toDate() : null}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xl={2} lg={3} sm={4} xs={6}>
                                <Form.Group className='mb-3'>
                                    <Form.Label>To Date</Form.Label>
                                    <div className="has-search-icon">
                                        <i className="fa fa-search has-icon"></i>
                                        <DatePicker
                                            className="form-control"
                                            selected={toDate ? moment(toDate).toDate() : null}
                                            minDate={fromDate ? moment(fromDate).toDate() : null}
                                            placeholderText="To Date"
                                            onChange={handleToDateChange}
                                            dateFormat="yyyy-MM-dd"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            disabled={!fromDate && true}
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xl={3} lg={3} sm={4} >
                                <Form.Group className='mb-3'>
                                    <Form.Label>Search</Form.Label>
                                    <div className="has-search-icon">
                                        <i className="fa fa-search has-icon"></i>
                                        <Form.Control placeholder="Search by Subscriber, Tank" onChange={handleSubscribersSearch} />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <Table responsive bordered>
                        <thead>
                            <tr>
                                <th>S. No.</th>
                                <th>Subscriber</th>
                                <th>Tank</th>
                                <th>Coins</th>
                                <th>Amount</th>
                                <th>Date</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {subscribersList && subscribersList?.length ?
                                <>
                                    {subscribersList?.map((data, index) => (
                                        <tr key={index}>
                                            <td>{pageNo * pageSize + index + 1}</td>
                                            <td>{data?.subscriber}</td>
                                            <td>{data?.tank}</td>
                                            <td>{numberFormatStyle(data?.coins)}</td>
                                            <td>{currencyFormat(data?.coinAmount)}</td>
                                            <td>{dateFormat(data?.purchasedAt)}</td>
                                            <td>{data?.isSubscriptionActive ? "Active" : 'Inactive'}</td>
                                        </tr>
                                    ))
                                    }
                                </>
                                :
                                <NoRecordsFound colspan={7} />
                            }
                        </tbody>
                    </Table>
                    <CommonPagination total={totalCount} pageSize={pageSize} currentPage={pageNo} onPageChange={handlePageChange} />
                </div>
            </div>
        </Layout>
    )
}

export default Subscriptions