import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Layout from '../../components/layout';
import { useDeleteCommentMutation, useGetCommentListQuery } from '../../Redux/api/contentApi';
import { constants } from '../../helper/constants';
import CommonPagination from '../../components/shared/CommonPagination';
import { showToast } from '../../helper/swal';
import Loader from '../../components/loader';
import Swal from 'sweetalert2';
import { removeSlash } from '../../helper/removeSlash';
import Images from '../../components/Images';
import { dateFormat } from '../../helper/dateFormat';

const CommentList = () => {
  const { id } = useParams();
  const [pageNo, setPageNo] = useState(constants?.common?.pageNo);
  const [pageSize] = useState(constants?.common?.pageSize);
  const [totalCount, setTotalCount] = useState(0);
  const [commentList, setCommentList] = useState([])

  const { refetch: refetchCommentList, data: commentListData, isFetching: isCommentListFetching, isSuccess: isCommentListSuccess, error: commentListError } = useGetCommentListQuery({
    pageNo: pageNo + 1,
    pageSize: pageSize,
    postId: id,
  });

  const [deleteComment, { data: deleteCommentData, isLoading: isDeleteCommentLoading, isSuccess: isDeleteCommentSuccess, error: deleteCommentError }] = useDeleteCommentMutation();

  // Page Change
  const handlePageChange = (page) => {
    const selectedPageNo = setPageNo(page.selected);
    return selectedPageNo;
  }

  // Delete Comment
  const handleDeleteComment = (getActionId, getCommentId) => {
    Swal.fire({
      title: 'Delete',
      text: constants?.validations?.deleteCommentText,
      icon: 'question',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      allowOutsideClick: false
    }).then(async (result) => {
      if (result.isConfirmed && result.value) {
        await deleteComment({ data: { isDeleted: true, postId: getActionId }, commentId: getCommentId })
      }
    })
  }

  // Comment List Response
  useEffect(() => {
    if (isCommentListSuccess) {
      setCommentList(commentListData?.data?.comments);
      setTotalCount(commentListData?.data?.totalComments)
    } else if (commentListError) {
      showToast(commentListError?.data?.message, "error", true)
    }
  }, [commentListData, isCommentListSuccess, commentListError])


  useEffect(() => {
    // Refetch comment list on page change
    refetchCommentList();
  }, [pageNo, pageSize, refetchCommentList])


  // Delete Comment API Response
  useEffect(() => {
    if (isDeleteCommentSuccess) {
      if (deleteCommentData?.success) {
        showToast(deleteCommentData?.message, "success", false, 3000);
        refetchCommentList();
      } else {
        showToast(deleteCommentData?.message, "error", true)
      }
    } else if (deleteCommentError) {
      showToast(deleteCommentError?.data?.message, "error", true)
    }
  }, [isDeleteCommentSuccess, deleteCommentData, deleteCommentError, refetchCommentList])

  return (
    <Layout>
      {(isCommentListFetching || isDeleteCommentLoading) && <Loader />}
      <div className='conent-header'>
        <h1 className='title-h1'>Post Comments</h1>
        <Breadcrumb>
          <BreadcrumbItem linkAs="span">
            <Link to="/content">Content Management</Link>
          </BreadcrumbItem>
          <BreadcrumbItem linkAs="span">
            <Link to={`/content/detail/${id}`}>Details</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Comments</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <Row className="justify-content-xl-center">
        <Col xl={8}>
          <div className='card'>
            <div className='card-body comment-scroller'>
              <ul className='charity-list'>
                {commentList && commentList?.length ?
                  <>{commentList?.map((data, index) => (
                    <li key={index}>
                      <div className='comment-inline-row'>
                        <div className='comment-col-left'>
                          <div className='user-c-thumb'>
                            <img src={data?.user?.profileDetail?.profilePic ? process.env.REACT_APP_BASE_IMG_URL +removeSlash(data?.user?.profileDetail?.profilePic):  Images?.PLACEHOLDER_IMG} alt=''/>
                          </div>
                        </div>
                        <div className='comment-col-right'>
                          <h6 className='mb-1'>{data?.user?.profileDetail?.username}</h6>
                          <p>{dateFormat(data?.createdAt)}</p>
                          <p className='mb-0'>
                            {data?.comment}
                          </p>
                        </div>
                      </div>
                      <ul className="action-btn-group">
                        <li>
                          <OverlayTrigger placement='top' overlay={<Tooltip>Delete</Tooltip>}>
                            <button type='button' className='btn btn-delete btn-icon' onClick={() => handleDeleteComment(data?.postId, data?._id)}>
                              <i className='fa fa-trash'></i>
                            </button>
                          </OverlayTrigger>
                        </li>
                      </ul>
                    </li>
                  ))
                  }
                  </> :
                  <p className='text-center'>
                    No Comments Found
                  </p>
                }
              </ul>
            </div>
          </div>
        </Col>
      </Row>
      <CommonPagination total={totalCount} pageSize={pageSize} currentPage={pageNo} onPageChange={handlePageChange} />
    </Layout>
  )
}

export default CommentList