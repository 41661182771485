
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from "redux-persist/lib/storage";
import authReducer from './slice/authSlice';
import verifyOtpReducer from './slice/verifyOtpSlice';
import manageReducer from './slice/manageSlice';
import { authApi } from './api/authApi';
import { persistStore, persistReducer } from "redux-persist";
import { charityApi } from './api/charityApi';
import { preSignedUrlApi } from './api/preSignedUrlApi';
import { profileApi } from './api/profileApi';
import { userApi } from './api/userApi';
import { reportApi } from './api/reportApi';
import { contentApi } from './api/contentApi';
import { paymentApi } from './api/paymentApi';

// making combine reducers
const rootReducer = combineReducers({
  auth: persistReducer({ key: "auth", storage }, authReducer),
  verifyOtp: persistReducer({ key: "verifyOtp", storage }, verifyOtpReducer),
  manage: persistReducer({ key: "manage", storage }, manageReducer),
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [contentApi.reducerPath]: contentApi.reducer,
  [charityApi.reducerPath]: charityApi.reducer,
  [preSignedUrlApi.reducerPath]: preSignedUrlApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [reportApi.reducerPath]: reportApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false, }).concat(
    [
      authApi.middleware,
      userApi.middleware,
      contentApi.middleware,
      charityApi.middleware,
      preSignedUrlApi.middleware,
      profileApi.middleware,
      reportApi.middleware,
      paymentApi.middleware
    ]
  ),
});

export const persistor = persistStore(store);