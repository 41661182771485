import React, { useState, useEffect } from 'react';
import Layout from '../../components/layout';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { encrypt } from '../../helper/crypto';
import { useChangePasswordMutation } from '../../Redux/api/profileApi';
import { constants } from '../../helper/constants';
import { showToast } from '../../helper/swal';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/loader';

const ChangePassword = () => {
  const navigate = useNavigate();

  const [changePassword, { data: changePasswordData, isLoading: isChangePasswordLoading, isSuccess: isChangePasswordSuccess, error: changePasswordError }] = useChangePasswordMutation();

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  }

  // Change Password Form Validation Schema
  const changePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string().required(constants?.validations?.required),
    newPassword: Yup.string().required(constants?.validations?.required)
      .matches(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
        "Password must contain at least 8 characters with 1 uppercase, 1 lowercase, 1 numeric, and 1 special character"
      )
      .notOneOf([Yup.ref("currentPassword")], "The Current password and New password must be different."),
    confirmNewPassword: Yup.string().required(constants?.validations?.required).oneOf([Yup.ref("newPassword")], "The New password and confirmation password must match."),
  });

  // Handle Submit
  const handleSubmit = async (values) => {
    const obj = {
      currentPassword: encrypt(values.currentPassword),
      newPassword: encrypt(values.newPassword),
    }
    await changePassword(obj);
  }

  // Change Password Response
  useEffect(() => {
    if (isChangePasswordSuccess) {
      if (changePasswordData?.success) {
        showToast(changePasswordData?.message, "success", false, 3000);
        navigate("/users");
      } else {
        showToast(changePasswordData?.message, "error", true)
      }
    } else if (changePasswordError) {
      showToast(changePasswordError?.data?.message, "error", true)
    }
  }, [isChangePasswordSuccess, changePasswordError, changePasswordData?.success, changePasswordData?.message, isChangePasswordLoading, navigate]);


  const PasswordField = ({ name }) => {
    // password toggle eye
    const [isVisible, setVisible] = useState(false);
    const togglePassword = () => {
      setVisible(!isVisible);
    }
    return (
      <div className="form-pass">
        <Field type={isVisible ? 'text' : 'password'} name={name} className="form-control" autoComplete="off"></Field>
        <button type="button" className='btn' onClick={() => togglePassword()}>
          <i className={isVisible ? "fa-sharp fa-solid fa-eye" : "fa-solid fa-eye-slash"}></i>
        </button>
      </div>
    )
  }

  return (
    <Layout>
      {isChangePasswordLoading ? <Loader /> : null}
      <div className='conent-header mb-5'>
        <h1 className='title-h1'>Change Password</h1>
      </div>
      <div className="auth-box">
        <div className="auth-card">
          <div className="auth-card-body">
            <Formik initialValues={initialValues} validationSchema={changePasswordSchema} onSubmit={handleSubmit}>
              {({ handleSubmit }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label className="form-label">Current Password</label>
                      <PasswordField name="currentPassword" />
                      <ErrorMessage name="currentPassword" component="div" className="error-text" />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">New Password</label>
                      <PasswordField name="newPassword" />
                      <ErrorMessage name="newPassword" component="div" className="error-text" />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Confirm New Password</label>
                      <PasswordField name="confirmNewPassword" />
                      <ErrorMessage name="confirmNewPassword" component="div" className="error-text" />
                    </div>
                    <div className="mt-4 mb-3">
                      <button type="submit" className="btn btn-primary w-100">
                        <span>Save</span>
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ChangePassword