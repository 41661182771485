import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Images from '../../components/Images';
import Loader from '../../components/loader';
import { useGetCharityDetailQuery } from '../../Redux/api/charityApi';
import { showToast } from '../../helper/swal';
import { capitalizeStr } from '../../helper/capitalizeStr';

const CharityDetail = () => {
  const { id } = useParams();
  const [imgUrl, setImgUrl] = useState(null);

  const { refetch: refetchCharityDetailData, data: charityDetailData, isFetching: charityDetailFetching, isSuccess: charityDetailSuccess, error: charityDetailError } = useGetCharityDetailQuery({
    charityId: id,
  });

  const [charityData, setCharityData] = useState({
    title: "",
    ein: "",
    city: "",
    state: "",
    zipCode: "",
    description: "",
    status: "",
    image: null
  });


  // Refetch Charity Detail
  useEffect(() => {
    refetchCharityDetailData();
  }, [refetchCharityDetailData])

  // Get Charity Detail
  useEffect(() => {
    if (charityDetailSuccess) {
      setImgUrl(charityDetailData?.data?.image && process.env.REACT_APP_BASE_IMG_URL + charityDetailData?.data?.image);
      setCharityData({
        title: charityDetailData?.data?.title,
        ein: charityDetailData?.data?.ein,
        city: charityDetailData?.data?.city,
        state: charityDetailData?.data?.state,
        zipCode: charityDetailData?.data?.zipCode,
        description: charityDetailData?.data?.description,
        status: charityDetailData?.data?.status,
        image: charityDetailData?.data?.image
      })
    } else if (charityDetailError) {
      showToast(charityDetailError?.data?.message, "error", true)
    }
  }, [charityDetailSuccess, charityDetailData?.data, charityDetailError]);

  return (
    <Layout>
      {charityDetailFetching ? <Loader /> : null}
      <div className='conent-header'>
        <h1 className='title-h1'>Charity Detail</h1>
        <Breadcrumb>
          <BreadcrumbItem linkAs="span">
            <Link to="/charity">All Charities</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Charity Detail</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div className='card'>
        <div className='card-body'>
          <Row>
            <Col xl={2} sm={3}>
              <div className="form-group d-flex align-items-center flex-column mb-0">
                <div className="img-thumb-box">
                  <div className="img-thumb">
                    <img src={imgUrl ? imgUrl : Images.PLACEHOLDER_IMG} alt="" />
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={10} sm={9}>
              <Row>
                <Col sm={4} xs={6}>
                  <div className="mb-3">
                    <label className='form-label'>Charity Name</label>
                    <p className='mb-0'>{capitalizeStr(charityData?.title)}</p>
                  </div>
                </Col>
                <Col sm={4} xs={6}>
                  <div className="mb-3">
                    <label className='form-label'>EIN Number</label>
                    <p className='mb-0'>{charityData?.ein}</p>
                  </div>
                </Col>
                <Col sm={4} xs={6}>
                  <div className="mb-3">
                    <label className='form-label'>Status</label>
                    <p className='mb-0'>{charityData?.status}</p>
                  </div>
                </Col>
                <Col sm={4} xs={6}>
                  <div className="mb-3">
                    <label className='form-label'>City</label>
                    <p className='mb-0'>{capitalizeStr(charityData?.city)}</p>
                  </div>
                </Col>
                <Col sm={4} xs={6}>
                  <div className="mb-3">
                    <label className='form-label'>State</label>
                    <p className='mb-0'>{charityData?.state}</p>
                  </div>
                </Col>
                <Col sm={4} xs={6}>
                  <div className="mb-3">
                    <label className='form-label'>Zip Code</label>
                    <p className='mb-0'>{charityData?.zipCode}</p>
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="mb-3">
                    <label className='form-label'>Description</label>
                    <p className='mb-0'>{charityData?.description}</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div className='text-center mt-4'>
        <Link to={`/charity/edit/${id}`} className='btn btn-primary btn-size-xs'>Edit</Link>
        <Link to="/charity" className='btn btn-secondary btn-size-xs ms-2'>Cancel</Link>
      </div>
    </Layout>
  )
}

export default CharityDetail