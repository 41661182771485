import { createSlice } from "@reduxjs/toolkit";

const verifyOtpSlice = createSlice({
  name: 'verifyOtp',
  initialState: {
    forgotToken: null,
  },
  reducers: {
    setForgotToken: (state, action) => {
      state.forgotToken = action.payload;
    }
  }
});

export const {setForgotToken} = verifyOtpSlice.actions;

export default verifyOtpSlice.reducer;