import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import Loader from '../../components/loader';
import { constants } from '../../helper/constants';
import NoRecordsFound from '../../components/shared/NoRecordsFound';
import CommonPagination from '../../components/shared/CommonPagination';
// import { debounce } from 'lodash';
import { showToast } from '../../helper/swal';
import { dateFormat } from '../../helper/dateFormat';
import { useGetDonationHistoryQuery } from '../../Redux/api/paymentApi';
import { capitalizeStr } from '../../helper/capitalizeStr';
import { currencyFormat} from '../../helper/numberFormat';
import { useParams } from 'react-router-dom';

const DonationTabData = () => {
  const { id } = useParams();
  const [pageNo, setPageNo] = useState(constants?.common?.pageNo);
  const [pageSize,setPageSize] = useState(constants?.common?.pageSize);
  const [totalCount, setTotalCount] = useState(0);
  // const [searchDonation, setSearchDonation] = useState("");
  const [donationList, setDonationList] = useState([]);

  const { refetch: refetchDonationHistory, data: donationHistoryData, isFetching: isDonationHistoryFetching, isSuccess: isDonationHistorySuccess, error: donationHistoryError } = useGetDonationHistoryQuery({
    userId: id,
    pageNo: pageNo + 1,
    pageSize: pageSize,
  });

  // Page Change
  const handlePageChange = (page) => {
    const selectedPageNo = setPageNo(page.selected);
    return selectedPageNo;
  }

  // Search Donation
  // const handleDonationSearch = debounce((e) => {
  //   setSearchDonation(e.target.value);
  //   setPageNo(0);
  // }, 500)

  useEffect(() => {
    // Refetch Donations list on page change
    refetchDonationHistory();
  }, [pageNo, pageSize, refetchDonationHistory])

    // Donations List Response
    useEffect(() => {
      if (isDonationHistorySuccess) {
        setDonationList(donationHistoryData?.data?.userDonationHistory);
        setTotalCount(donationHistoryData?.data?.totalCount)
      } else if (donationHistoryError) {
        showToast(donationHistoryError?.data?.message, "error", true)
      }
    }, [donationHistoryData, isDonationHistorySuccess, donationHistoryError])


  return (
    <>
     {(isDonationHistoryFetching) && <Loader />}
    <div className='card'>
      <div className='card-body'>
        <div className='card-filter'>
          <Row className="justify-content-end">
            <Col lg={2} sm={4}>
              <Form.Group className='mb-3'>
                <Form.Label>Page Options</Form.Label>
                <Form.Select onChange={(e) => { setPageSize(e.target.value); setPageNo(0) }}>
                  {constants?.pageOptions?.map((item, index) => (
                    <option value={item.value} key={index}>{item.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            {/* <Col xl={2} lg={3} xs={6}>
              <Form.Group className='mb-3'>
                <Form.Label>Search Charity</Form.Label>
                <div className="has-search-icon">
                  <i className="fa fa-search has-icon"></i>
                  <Form.Control placeholder="Search by charity" onChange={handleDonationSearch} />
                </div>
              </Form.Group>
            </Col> */}
          </Row>
        </div>
        <Table responsive bordered>
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Charity</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Amount</th>
              {/* <th>Receipt</th> */}
            </tr>
          </thead>
          <tbody>
            {donationList && donationList?.length ?
              <>
                {donationList?.map((data, index) => (
                  <tr key={index}>
                    <td>{pageNo * pageSize + index + 1}</td>
                    <td>{capitalizeStr(data?.title)}</td>
                    <td>{dateFormat(data?.supportFromDate)}</td>
                    <td>{dateFormat(data?.supportEndDate)}</td>
                    <td>{currencyFormat(data?.totalAmountDonated)}</td>
                    {/* <td>{data?.receipt}</td> */}
                  </tr>
                ))
                }
              </>
              :
              <NoRecordsFound colspan={6} />
            }
          </tbody>
        </Table>
        <CommonPagination total={totalCount} pageSize={pageSize} currentPage={pageNo} onPageChange={handlePageChange} />
      </div>
    </div>
    </>
    
  )
}

export default DonationTabData