import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Images from '../../components/Images'
import { Container } from 'react-bootstrap'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from "yup";
import { useLoginMutation } from '../../Redux/api/authApi'
import { useDispatch } from 'react-redux'
import { loginUser } from '../../Redux/slice/authSlice'
import { showToast } from '../../helper/swal'
import { encrypt } from '../../helper/crypto'

const Login = () => {
  const [passwordType, setPasswordType] = useState('password');
  const [login, response] = useLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Password type toggle
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType('text')
    } else {
      setPasswordType("password")
    }
  }

  const initialValues = {
    email: "",
    password: "",
    deviceType: "2",
    deviceId: "2",
    deviceToken: "2",
    role: "admin"
  }
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Please enter a valid email id").required("Email is required"),
    password: Yup.string().required("Password is required"),
  })

  // Handle Submit
  const handleSubmit = async (values) => {
    const obj = {
      email: encrypt(values.email),
      password: encrypt(values.password),
      deviceType: "IOS",
      deviceId: "2",
      deviceToken: "2",
      role: "admin"
    }
    await login(obj);
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user')) || null;
    if (user) {
      navigate('/charity');
    }
  });

  useEffect(() => {
    if (response?.isSuccess) {
      showToast(response?.data?.message, "success", false, 3000);
      // dispacthing user data
      dispatch(loginUser(response?.data));
      navigate("/users");
    }
    else if (response?.error) {
      showToast(response?.error?.data?.message, "error", true)
    }
  }, [response?.isSuccess, response?.data, response?.error, navigate, dispatch])

  return (
    <div className="auth-page-wrapper">
      <Container>
        <div className="auth-box">
          <div className="text-center mb-4">
            <img src={Images.LOGO} className="header-logo" alt="Brand Logo" />
          </div>
          <div className="auth-card">
            <div className="auth-card-body">
              <div className="text-center mb-4">
                <h1>Login</h1>
              </div>
              <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                {({ handleSubmit }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label className="form-label">Email</label>
                        <Field type="text" className="form-control" name="email" />
                        <ErrorMessage name="email" component="div" className="error-text" />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Password</label>
                        <div className="form-pass">
                          <Field type={passwordType} className="form-control" name="password" />
                          <button type="button" className='btn' onClick={togglePassword}>
                            {passwordType === "password" ? (
                              <i className="fa-solid fa-eye-slash"></i>
                            ) :
                              (
                                <i className="fa-solid fa-eye"></i>
                              )}
                          </button>
                        </div>
                        <ErrorMessage name="password" component="div" className="error-text" />
                      </div>
                      <div className="mt-4 mb-3">
                        <button type="submit" className={response?.isLoading ? "btn btn-primary w-100 pe-none" : "btn btn-primary w-100"}>
                          {response?.isLoading ?
                            <>
                              <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                              Loading...
                            </> :
                            <>
                              <span>Log in</span>
                            </>
                          }
                        </button>
                      </div>
                      <div className="mb-1">
                        <Link to="/forgot-password">Forgot Password?</Link>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Login