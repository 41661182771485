import React from 'react';
import { Link } from "react-router-dom";
import Images from '../Images';
import { useDispatch, useSelector } from 'react-redux';
import { setToggleSubmenu } from '../../Redux/slice/manageSlice';

const Sidebar = () => {
  const dispatch = useDispatch();
  const setting = useSelector((state) => state.manage.setting);

  const openSubmenu = (status) => {
    dispatch(setToggleSubmenu(status));
  }
  return (
    <div className="sidebar-wrapper">
      <div className='brand-link'>
        <img src={Images.LOGO_BLACK} alt='Brand Logo' />
      </div>
      <ul className="sidebar-menu">
        <li>
          <Link to="/users" className={`nav-item ${window.location.pathname.includes("users") ? "active" : ""}`} onClick={() => openSubmenu(false)}>
            <i className="fa-solid fa-users"></i>
            <span className="nav-text">User Management</span>
          </Link>
        </li>
        <li>
          <Link to="/content" className={`nav-item ${window.location.pathname.includes("content") ? "active" : ""}`} onClick={() => openSubmenu(false)}>
            <i className="fa-solid fa-layer-group"></i>
            <span className="nav-text">Content Management</span>
          </Link>
        </li>
        <li>
          <Link to="/charity" className={`nav-item ${window.location.pathname.includes("charity") ? "active" : ""}`} onClick={() => openSubmenu(false)}>
            <i className="fa-solid fa-circle-dollar-to-slot"></i>
            <span className="nav-text">Charity Management</span>
          </Link>
        </li>
        <li>
          <Link to="/reports" className={`nav-item ${window.location.pathname.includes("reports") ? "active" : ""}`} onClick={() => openSubmenu(false)}>
            <i className="fa-solid fa-file"></i>
            <span className="nav-text">Reporting</span>
          </Link>
        </li>
        <li>
          <div className={`nav-item ${setting.toggleSubmenu ? 'is-open' : ""}  ${window.location.pathname.includes("payments") ? "active" : ""}`} onClick={() => openSubmenu(!setting.toggleSubmenu)}>
            <i className="fa-solid fa-money-check-dollar"></i>
            <span className="nav-text">Payments</span>
            <i className="fa-solid fa-angle-down"></i>
          </div>
          <ul>
            <li >
              <Link to="/payments/subscriptions" className={`nav-item ${window.location.pathname.includes("subscriptions") ? "active" : ""}`} >
                <i className={`fa-circle ${window.location.pathname.includes("subscriptions") ? "fa-solid" : "fa-regular"}`}></i>
                <span className="nav-text">Subscriptions</span>
              </Link>
            </li>
            <li>
              <Link to="/payments/donations" className={`nav-item ${window.location.pathname.includes("donations") ? "active" : ""}`}>
                <i className={`fa-circle ${window.location.pathname.includes("donations") ? "fa-solid" : "fa-regular"}`}></i>
                <span className="nav-text">Donations</span>
              </Link>
            </li>
            <li>
              <Link to="/payments/payouts" className={`nav-item ${window.location.pathname.includes("payouts") ? "active" : ""}`}>
                <i className={`fa-circle ${window.location.pathname.includes("payouts") ? "fa-solid" : "fa-regular"}`}></i>
                <span className="nav-text">Payouts</span>
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  )
}

export default Sidebar