import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import { Link } from 'react-router-dom';
import { Col, Form, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import { constants } from '../../helper/constants';
import { useEditCharityMutation, useGetCharityListQuery } from '../../Redux/api/charityApi';
import Loader from '../../components/loader';
import { capitalizeStr } from '../../helper/capitalizeStr';
import NoRecordsFound from '../../components/shared/NoRecordsFound';
import { debounce } from 'lodash';
import { showToast } from '../../helper/swal';
import Swal from 'sweetalert2';
import CommonPagination from '../../components/shared/CommonPagination';
import { currencyFormat, numberFormatStyle } from '../../helper/numberFormat';

const CharityManagement = () => {
  const [pageNo, setPageNo] = useState(constants?.common?.pageNo);
  const [pageSize, setPageSize] = useState(constants?.common?.pageSize);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const [charityList, setCharityList] = useState([]);

  const { refetch: refetchCharityList, data: charityData, isFetching: ischarityFetching, isSuccess: isCharitySuccess, error: charityError } = useGetCharityListQuery({
    pageNo: pageNo + 1,
    pageSize: pageSize,
    status: status,
    searchParam: searchText
  });

  const [editCharity, { data: editCharityData, isLoading: isEditCharityLoading, isSuccess: isEditCharitySuccess, error: editCharityError }] = useEditCharityMutation();

  // Page Change
  const handlePageChange = (page) => {
    const selectedPageNo = setPageNo(page.selected);
    return selectedPageNo;
  }

  //Get Charity Status
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    setPageNo(0);
  }

  // Search Charity
  const handleCharitySearch = debounce((e) => {
    setSearchText(e.target.value);
    setPageNo(0)
  }, 500)

  // Chairty Status Update
  const handleStatusUpdate = (getActionType, getActionId) => {
    Swal.fire({
      title: getActionType === "inactive" ? "Inactive" : getActionType === "active" ? "Active" : getActionType === "deleted" ? "Delete" : "",
      text: getActionType === "inactive" ? constants?.validations?.statusInactive : getActionType === "active" ? constants?.validations?.statusActive : getActionType === "deleted" ? constants?.validations?.deleteChairty : "",
      icon: 'question',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      allowOutsideClick: false
    }).then(async (result) => {
      if (result.isConfirmed && result.value) {
        if (getActionType === "deleted") {
          await editCharity({ data: { status: "deleted" }, updateCharityId: getActionId })
        } else {
          await editCharity({ data: { status: getActionType === "inactive" ? "inactive" : "active" }, updateCharityId: getActionId })
        }
      }
    })
  }

  // Charity List Response
  useEffect(() => {
    if (isCharitySuccess) {
      setCharityList(charityData?.data?.charities);
      setTotalCount(charityData?.data?.totalCount)
    } else if (charityError) {
      showToast(charityError?.data?.message, "error", true)
    }
  }, [charityData, isCharitySuccess, charityError])

  useEffect(() => {
    // Refetch Charity list on page change
    refetchCharityList();
  }, [pageNo, pageSize, status, searchText, refetchCharityList])

  // Update Charity Status Message
  useEffect(() => {
    if (isEditCharitySuccess) {
      if (editCharityData?.success) {
        showToast(editCharityData?.message, "success", false, 3000);
        refetchCharityList();
      } else {
        showToast(editCharityData?.message, "error", true)
      }
    } else if (editCharityError) {
      showToast(editCharityError?.data?.message, "error", true)
    }
  }, [isEditCharitySuccess, editCharityData?.success, editCharityData?.message, editCharityError, refetchCharityList])

  return (
    <Layout>
      {(ischarityFetching || isEditCharityLoading) && <Loader />}
      <div className='conent-header flex-nowrap'>
        <h1 className='title-h1 w-auto'>All Charities</h1>
        <Link className='btn btn-primary' to="/charity/search">Add New</Link>
      </div>
      <div className='card'>
        <div className='card-body'>
          <div className='card-filter'>
            <Row className="justify-content-end">
              <Col xl={2} sm={4} xs={6}>
                <Form.Group className='mb-3'>
                  <Form.Label>Page Options</Form.Label>
                  <Form.Select onChange={(e) => { setPageSize(e.target.value); setPageNo(0) }}>
                    {constants?.pageOptions?.map((item, index) => (
                      <option value={item.value} key={index}>{item.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xl={2} sm={4} xs={6}>
                <Form.Group className='mb-3'>
                  <Form.Label>Status</Form.Label>
                  <Form.Select onChange={handleStatusChange}>
                    <option value="">Select Status</option>
                    {constants?.status?.map((item, index) => (
                      <option value={item.value} key={index}>{item.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={3} sm={4}>
                <Form.Group className='mb-3'>
                  <Form.Label>Search</Form.Label>
                  <div className="has-search-icon">
                    <i className="fa fa-search has-icon"></i>
                    <Form.Control placeholder="Search by Charity Name" onChange={handleCharitySearch} />
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Charity Name</th>
                <th>EIN Number</th>
                <th>Total Supporters</th>
                <th>Amount Donated</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {charityList && charityList?.length ?
                <>
                  {charityList?.map((data, index) => (
                    <tr key={index}>
                      <td>{pageNo * pageSize + index + 1}</td>
                      <td>{capitalizeStr(data?.title)}</td>
                      <td>{data?.ein}</td>
                      <td>{numberFormatStyle(data?.totalSupporterCount)}</td>
                      <td>{currencyFormat(data?.amountDonated)}</td>
                      <td>
                        <label className="toggle-switch">
                          <input type="checkbox" checked={data?.status === "active"} onChange={() => handleStatusUpdate(data?.status === "active" ? "inactive" : "active", data?._id)} />
                          <div className="switch-label">
                            <span className="switch-circle"></span>
                          </div>
                        </label>
                      </td>
                      <td>
                        <ul className="action-btn-group">
                          <li>
                            <OverlayTrigger placement='top' overlay={<Tooltip>View</Tooltip>}>
                              <Link className='btn btn-icon btn-view' to={`/charity/detail/${data?._id}`}>
                                <i className="fa-regular fa-eye"></i>
                              </Link>
                            </OverlayTrigger>
                          </li>
                          <li>
                            <OverlayTrigger placement='top' overlay={<Tooltip>Edit</Tooltip>}>
                              <Link className='btn btn-icon btn-edit' to={`/charity/edit/${data?._id}`}>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </Link>
                            </OverlayTrigger>
                          </li>
                          <li>
                            <OverlayTrigger placement='top' overlay={<Tooltip>Delete</Tooltip>}>
                              <button type='button' className='btn btn-delete btn-icon' onClick={() => handleStatusUpdate("deleted", data?._id)}>
                                <i className="fa-solid fa-trash"></i>
                              </button>
                            </OverlayTrigger>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  ))
                  }
                </> :
                <NoRecordsFound colspan={7} />
              }
            </tbody>
          </Table>
          <CommonPagination total={totalCount} pageSize={pageSize} currentPage={pageNo} onPageChange={handlePageChange} />
        </div>
      </div>
    </Layout>
  )
}

export default CharityManagement