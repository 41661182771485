import { createSlice } from "@reduxjs/toolkit";

const manageSlice = createSlice({
  name: 'manage',
  initialState: {
    setting:{
        toggleSubmenu: false,
      }
  },
  reducers: {
    setToggleSubmenu: (state, action) => {
      state.setting.toggleSubmenu = action.payload;
    },
  }
});

export const { setToggleSubmenu } = manageSlice.actions;

export default manageSlice.reducer;