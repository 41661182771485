import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout'
import { Breadcrumb, BreadcrumbItem, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom'
import UserInfoTab from '../../components/tabs/UserInfoTab'
import DonationTabData from '../../components/tabs/DonationTabData';
import WithdrawalTabData from '../../components/tabs/WithdrawalTabData';
import { useGetUserEarningDetailQuery } from '../../Redux/api/paymentApi';
import { showToast } from '../../helper/swal';
import { capitalizeStr } from '../../helper/capitalizeStr';
import { currencyFormat } from '../../helper/numberFormat';

const Earnings = () => {
  const { id } = useParams();
  const [tabkey, setTabKey] = useState('donation');

  const { refetch: refetchUserEarning, data: UserEarningData, isSuccess: isUserEarningSuccess, error: userEarningError } = useGetUserEarningDetailQuery({
    userId: id,
  });
  const [userEarning, setUserEarning] = useState({
    title: '',
    coinEarned: '',
    currentRedeemableAmount: '',
    totalDonation: '',
    userTotalEarningAmount: '',
    userTotalDonationToCurrentCharity: '',
  });

  // Get User Earning Detail
  useEffect(() => {
    if (isUserEarningSuccess) {
      setUserEarning((prevPayoutData) => ({
        ...prevPayoutData,
        title: UserEarningData?.data?.charityId?.title,
        currentRedeemableAmount: UserEarningData?.data?.currentRedeemableAmount,
        userTotalEarningAmount: UserEarningData?.data?.userTotalEarningAmount
      }))
    } else if (userEarningError) {
      showToast(userEarningError?.data?.message, "error", true)
    }
  }, [isUserEarningSuccess, UserEarningData?.data, userEarningError]);

  // Refetch User Earning Detail
  useEffect(() => {
    refetchUserEarning();
  }, [refetchUserEarning])

  return (
    <Layout>
      <div className='conent-header'>
        <h1 className='title-h1'>User Details</h1>
        <Breadcrumb>
          <BreadcrumbItem linkAs="span">
            <Link to="/users">All Users</Link>
          </BreadcrumbItem>
          <BreadcrumbItem linkAs="span">
            <Link to={`/users/personal-details/${id}`}>User Details</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Earnings</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div className="tab-container">
        <UserInfoTab />
        <div className='card mb-4'>
          <div className='card-body'>
            <Row>
              <Col sm={4} xs={6}>
                <div className="mb-3">
                  <label className='form-label'>Current Charity</label>
                  <p className='mb-0'>{capitalizeStr(userEarning?.title)}</p>
                </div>
              </Col>
              <Col sm={4} xs={6}>
                <div className="mb-3">
                  <label className='form-label'>Total Earnings</label>
                  <p className='mb-0'>{currencyFormat(userEarning?.userTotalEarningAmount)}</p>
                </div>
              </Col>
              <Col sm={4} xs={6}>
                <div className="mb-3">
                  <label className='form-label'>Redeemable Amount</label>
                  <p className='mb-0'>{currencyFormat(userEarning?.currentRedeemableAmount)}</p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className='tab-container'>
          <Tabs
            id="followersTab"
            activeKey={tabkey}
            onSelect={(k) => setTabKey(k)}
            className="mb-4 justify-content-center"
          >
            <Tab eventKey="donation" title="Donation History">
              {tabkey === "donation" &&
                <DonationTabData />
              }
            </Tab>
            <Tab eventKey="withdrawal" title="Withdrawal History">
              {tabkey === "withdrawal" &&
                <WithdrawalTabData />
              }
            </Tab>
          </Tabs>
        </div>
      </div>
    </Layout>
  )
}

export default Earnings