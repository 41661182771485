import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { Container } from 'react-bootstrap';
import Images from '../../components/Images';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { useDispatch } from 'react-redux';
import { setForgotToken } from '../../Redux/slice/verifyOtpSlice'
import { useForgotPasswordMutation } from '../../Redux/api/authApi';
import { encrypt } from '../../helper/crypto';
import { constants } from '../../helper/constants';
import { showToast } from '../../helper/swal';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [forgotPassword, { data: forgotPasswordData, isLoading: isForgotPasswordLoading, isSuccess: isForgotPasswordSuccess, error: forgotPasswordError }] = useForgotPasswordMutation();

  const initialValues = {
    role: "admin",
    email: "",
  }

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email("Please enter a valid email id").required(constants?.validations?.required),
  })

  // Handle Submit
  const handleSubmit = async (values) => {
    const obj = {
      role: "admin",
      email: encrypt(values.email),
    }
    await forgotPassword(obj);
  }

  // Change Password Response
  useEffect(() => {
    if (isForgotPasswordSuccess) {
      showToast(forgotPasswordData?.message, "success", false, 3000);
      dispatch(setForgotToken(forgotPasswordData?.data));
      navigate("/verify-otp");
    } else if (forgotPasswordError) {
      showToast(forgotPasswordError?.data?.message, "error", true)
    }
  }, [isForgotPasswordSuccess, forgotPasswordError, forgotPasswordData, forgotPasswordData?.message, isForgotPasswordLoading, navigate, dispatch]);


  return (
    <div className="auth-page-wrapper">
      <Container>
        <div className="auth-box">
          <div className="text-center mb-4">
            <img src={Images.LOGO} className="header-logo" alt="Brand Logo" />
          </div>
          <div className="auth-card">
            <div className="auth-card-body">
              <div className="text-center mb-4">
                <h1>Forgot Password</h1>
                <p>Enter your registered email address to reset password</p>
              </div>
              <Formik initialValues={initialValues} validationSchema={forgotPasswordSchema} onSubmit={handleSubmit}>
                {({ handleSubmit }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label className="form-label">Email</label>
                        <Field type="text" className="form-control" name="email" />
                        <ErrorMessage name="email" component="div" className="error-text" />
                      </div>
                      <div className="mt-4 mb-3">
                        <button type="submit" className={isForgotPasswordLoading ? "btn btn-primary w-100 pe-none" : "btn btn-primary w-100"}>
                          {isForgotPasswordLoading ?
                            <>
                              <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                              Loading...
                            </> :
                            <span>Submit</span>
                          }
                        </button>
                      </div>
                      <div className="mb-1">
                        Have an account?<Link to="/" className="ms-1">Login</Link>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default ForgotPassword