import React, { useEffect, useState } from 'react';
import { Col, Form, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import Layout from '../../../components/layout'
import { useGetPayoutsListQuery } from '../../../Redux/api/paymentApi';
import Loader from '../../../components/loader';
import { constants } from '../../../helper/constants';
import NoRecordsFound from '../../../components/shared/NoRecordsFound';
import CommonPagination from '../../../components/shared/CommonPagination';
import { debounce } from 'lodash';
import { showToast } from '../../../helper/swal';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { dateFormat } from '../../../helper/dateFormat';
import { Link } from 'react-router-dom';
import { currencyFormat, numberFormatStyle } from '../../../helper/numberFormat';
import { capitalizeStr } from '../../../helper/capitalizeStr';

const Payouts = () => {
    const [pageNo, setPageNo] = useState(constants?.common?.pageNo);
    const [pageSize, setPageSize] = useState(constants?.common?.pageSize);
    const [totalCount, setTotalCount] = useState(0);
    const [searchUsername, setSearchUsername] = useState("");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState("");
    const [subscribersList, setSubscribersList] = useState([]);

    const { refetch: refetchPayoutsList, data: payoutsListData, isFetching: isPayoutsListFetching, isSuccess: isPayoutsListSuccess, error: payoutsListError } = useGetPayoutsListQuery({
        pageNo: pageNo + 1,
        pageSize: pageSize,
        searchUsername: searchUsername,
        fromDate: fromDate,
        toDate: toDate
    });

    // Page Change
    const handlePageChange = (page) => {
        const selectedPageNo = setPageNo(page.selected);
        return selectedPageNo;
    }

    // Search Username
    const handleUsernameSearch = debounce((e) => {
        setSearchUsername(e.target.value);
        setPageNo(0);
    }, 500)

    // From Date
    const handleFromDateChange = (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        setFromDate(formattedDate);
        setPageNo(0);
    }

    // To Date
    const handleToDateChange = (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        setToDate(formattedDate);
        setPageNo(0);
    }

    useEffect(() => {
        // Refetch Payouts list on page change
        refetchPayoutsList();
    }, [pageNo, pageSize, searchUsername, refetchPayoutsList])


    // Payouts List Response
    useEffect(() => {
        if (isPayoutsListSuccess) {
            setSubscribersList(payoutsListData?.data?.payout);
            setTotalCount(payoutsListData?.data?.totalCount)
        } else if (payoutsListError) {
            showToast(payoutsListError?.data?.message, "error", true)
        }
    }, [payoutsListData, isPayoutsListSuccess, payoutsListError])

    return (
        <Layout>
            {(isPayoutsListFetching) && <Loader />}
            <div className='conent-header'>
                <h1 className='title-h1'>Payouts</h1>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <div className='card-filter'>
                        <Row className="justify-content-end">
                            <Col xl={2} sm={3} xs={6}>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Page Options</Form.Label>
                                    <Form.Select onChange={(e) => { setPageSize(e.target.value); setPageNo(0) }}>
                                        {constants?.pageOptions?.map((item, index) => (
                                            <option value={item.value} key={index}>{item.name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xl={2} sm={3} xs={6}>
                                <Form.Group className='mb-3'>
                                    <Form.Label>From Date</Form.Label>
                                    <div className="has-search-icon">
                                        <i className="fa fa-search has-icon"></i>
                                        <DatePicker
                                            className="form-control"
                                            selected={fromDate ? moment(fromDate).toDate() : null}
                                            placeholderText="From Date"
                                            onChange={handleFromDateChange}
                                            dateFormat="yyyy-MM-dd"
                                            maxDate={toDate ? moment(toDate).toDate() : null}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xl={2} sm={3} xs={6}>
                                <Form.Group className='mb-3'>
                                    <Form.Label>To Date</Form.Label>
                                    <div className="has-search-icon">
                                        <i className="fa fa-search has-icon"></i>
                                        <DatePicker
                                            className="form-control"
                                            selected={toDate ? moment(toDate).toDate() : null}
                                            minDate={fromDate ? moment(fromDate).toDate() : null}
                                            placeholderText="To Date"
                                            onChange={handleToDateChange}
                                            dateFormat="yyyy-MM-dd"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            disabled={!fromDate && true}
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xl={3} sm={3} xs={6}>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Search Username</Form.Label>
                                    <div className="has-search-icon">
                                        <i className="fa fa-search has-icon"></i>
                                        <Form.Control placeholder="Search by username" onChange={handleUsernameSearch} />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <Table responsive bordered>
                        <thead>
                            <tr>
                                <th>S. No.</th>
                                <th>Username</th>
                                <th>Coins</th>
                                <th>Amount</th>
                                <th>Requested Date</th>
                                <th>Payout Method</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {subscribersList && subscribersList?.length ?
                                <>
                                    {subscribersList?.map((data, index) => (
                                        <tr key={index}>
                                            <td>{pageNo * pageSize + index + 1}</td>
                                            <td>{data?.username}</td>
                                            <td>{numberFormatStyle(data?.coin)}</td>
                                            <td>{currencyFormat(data?.amount/100)}</td>
                                            <td>{dateFormat(data?.withdrawalAt)}</td>
                                            <td>{capitalizeStr(data?.payoutMethod)}</td>
                                            <td>
                                                <ul className="action-btn-group">
                                                    <li>
                                                        <OverlayTrigger placement='top' overlay={<Tooltip>View</Tooltip>}>
                                                            <Link className='btn btn-icon btn-view' to={`/payments/payouts/detail/${data?.userId}`}>
                                                                <i className="fa-regular fa-eye"></i>
                                                            </Link>
                                                        </OverlayTrigger>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                </>
                                :
                                <NoRecordsFound colspan={7} />
                            }
                        </tbody>
                    </Table>
                    <CommonPagination total={totalCount} pageSize={pageSize} currentPage={pageNo} onPageChange={handlePageChange} />
                </div>
            </div>
        </Layout>
    )
}

export default Payouts