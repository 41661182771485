
import { createApi} from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../../utils/baseQuery";

export const preSignedUrlApi = createApi({
  reducerPath: "preSignedUrlApi",
  baseQuery: baseQueryWithReauth,
  
  endpoints: (builder) => ({
    preSignedUrl: builder.mutation({
      query: (data) => {
        return {
          url: `/user/v1/signed-url`,
          method: 'POST',
          headers: {
            client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`
          },
          body: data
        }
      },
    }),
    updateImage: builder.mutation({
      query: (data) => {
        return {
          url: data.url,
          method: "PUT",
          headers: {},
          body: data.file,
        };
      },
    }),
  }),
});

export const {
  usePreSignedUrlMutation,
  useUpdateImageMutation
} = preSignedUrlApi;