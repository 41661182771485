import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Button, Col, Row } from 'react-bootstrap';
import Layout from '../../components/layout'
import UserInfoTab from '../../components/tabs/UserInfoTab';
import { useGetUserInfoQuery } from '../../Redux/api/profileApi';
import { showToast } from '../../helper/swal';
import Loader from '../../components/loader';
import FollowersSubscribers from '../../components/modals/FollowersSubscribers';
import FollowedSubscribed from '../../components/modals/FollowedSubscribed';
import { numberFormatStyle } from '../../helper/numberFormat';

const ProfileDetails = () => {
  const { id } = useParams();
  const [openFollowersModal, setOpenFollowersModal] = useState(false);
  const [openFollowedModal, setOpenFollowedModal] = useState(false);

  const [userProfileInfo, setUserProfileInfo] = useState({
    coinEarned: "",
    exclusivePostCount: "",
    followedCount: "",
    followerCount: "",
    nonExclusivePostCount: "",
    redeemableCoin: "",
    subscribedCount: "",
    subscriberCount: "",
    totalCoins: "",
    totalFollowedSubscribed: "",
    totalFollowersSubscribers: "",
    totalPostCount: "",
  })

  const { refetch: refetchUserProfileData, data: userProfileData, isFetching: isUserProfileDataFetching, isSuccess: isUserProfileDataSuccess, error: userProfileDataError } = useGetUserInfoQuery({
    userId: id,
    tabType: 2
  });

  useEffect(() => {
    // Refetch User Personal Info
    refetchUserProfileData();
  }, [refetchUserProfileData])

  // User Personal Info Response
  useEffect(() => {
    if (isUserProfileDataSuccess) {
      setUserProfileInfo({
        coinEarned: userProfileData?.data?.data[0].coinEarned,
        exclusivePostCount: userProfileData?.data?.data[0].exclusivePostCount,
        followedCount: userProfileData?.data?.data[0].followedCount,
        followerCount: userProfileData?.data?.data[0].followerCount,
        nonExclusivePostCount: userProfileData?.data?.data[0].nonExclusivePostCount,
        redeemableCoin: userProfileData?.data?.data[0].redeemableCoin,
        subscribedCount: userProfileData?.data?.data[0].subscribedCount,
        subscriberCount: userProfileData?.data?.data[0].subscriberCount,
        totalCoins: userProfileData?.data?.data[0].totalCoins,
        totalFollowedSubscribed: userProfileData?.data?.data[0].totalFollowedSubscribed,
        totalFollowersSubscribers: userProfileData?.data?.data[0].totalFollowersSubscribers,
        totalPostCount: userProfileData?.data?.data[0].totalPostCount,
      });
    } else if (userProfileDataError) {
      showToast(userProfileDataError?.data?.message, "error", true)
    }
  }, [userProfileData, isUserProfileDataSuccess, userProfileDataError])

  return (
    <Layout>
      {isUserProfileDataFetching ? <Loader /> : null}
      <div className='conent-header'>
        <h1 className='title-h1'>User Details</h1>
        <Breadcrumb>
          <BreadcrumbItem linkAs="span">
            <Link to="/users">All Users</Link>
          </BreadcrumbItem>
          <BreadcrumbItem linkAs="span">
            <Link to={`/users/personal-details/${id}`}>User Details</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Profile</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div className="tab-container">
        <UserInfoTab />
        <Row>
          <Col xl={3} sm={6} className='mb-3'>
            <div className='card dashboard-card'>
              <div className='card-body'>
                <div className='dashboard-icon'>
                  <i className="fa-solid fa-users"></i>
                </div>
                <div className='dashboard-data mb-4'>
                  <h2>Guppies</h2>
                  <div>
                    <dl>
                      <dt>Total</dt>
                      <dd>{numberFormatStyle(userProfileInfo?.totalFollowersSubscribers)}</dd>
                    </dl>
                    <dl>
                      <dt>Joined</dt>
                      <dd>{numberFormatStyle(userProfileInfo?.followerCount ? userProfileInfo?.followerCount : 0)}</dd>
                    </dl>
                    <dl>
                      <dt>Subscribers</dt>
                      <dd>{numberFormatStyle(userProfileInfo?.subscriberCount ? userProfileInfo?.subscriberCount : 0)}</dd>
                    </dl>
                  </div>
                </div>
                <div className='text-center'>
                  <Button variant="primary" className='btn-size-sm' onClick={() => setOpenFollowersModal(true)}>View Details</Button>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={3} sm={6} className='mb-3'>
            <div className='card dashboard-card'>
              <div className='card-body'>
                <div className='dashboard-icon'>
                  <i className="fa-solid fa-book"></i>
                </div>
                <div className='dashboard-data'>
                  <h2>Post</h2>
                  <div>
                    <dl>
                      <dt>Total</dt>
                      <dd>{numberFormatStyle(userProfileInfo?.totalPostCount)}</dd>
                    </dl>
                    <dl>
                      <dt>Public</dt>
                      <dd>{numberFormatStyle(userProfileInfo?.nonExclusivePostCount)}</dd>
                    </dl>
                    <dl>
                      <dt>Locked</dt>
                      <dd>{numberFormatStyle(userProfileInfo?.exclusivePostCount)}</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={3} sm={6} className='mb-3'>
            <div className='card dashboard-card'>
              <div className='card-body'>
                <div className='dashboard-icon'>
                  <i className="fa-solid fa-users"></i>
                </div>
                <div className='dashboard-data mb-4'>
                  <h2>Joined Tank</h2>
                  <div>
                    <dl>
                      <dt>Total</dt>
                      <dd>{numberFormatStyle(userProfileInfo?.totalFollowedSubscribed)}</dd>
                    </dl>
                    <dl>
                      <dt>Joined</dt>
                      <dd>{numberFormatStyle(userProfileInfo?.followedCount ? userProfileInfo?.followedCount : 0)}</dd>
                    </dl>
                    <dl>
                      <dt>Subscribed</dt>
                      <dd>{numberFormatStyle(userProfileInfo?.subscribedCount ? userProfileInfo?.subscribedCount : 0)}</dd>
                    </dl>
                  </div>
                </div>
                <div className='text-center'>
                  <Button variant="primary" className='btn-size-sm' onClick={() => setOpenFollowedModal(true)}>View Details</Button>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={3} sm={6} className='mb-3'>
            <div className='card dashboard-card'>
              <div className='card-body'>
                <div className='dashboard-icon'>
                  <i className="fa-solid fa-coins"></i>
                </div>
                <div className='dashboard-data'>
                  <h2>Coins</h2>
                  <div>
                    <dl>
                      <dt>Total</dt>
                      <dd>{numberFormatStyle(userProfileInfo?.totalCoins)}</dd>
                    </dl>
                    <dl>
                      <dt>Current</dt>
                      <dd>{numberFormatStyle( userProfileInfo?.coinEarned ? userProfileInfo?.coinEarned : 0)}</dd>
                    </dl>
                    <dl>
                      <dt>Redeemable</dt>
                      <dd>{numberFormatStyle(userProfileInfo?.redeemableCoin ? userProfileInfo?.redeemableCoin : 0)}</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {openFollowersModal && <FollowersSubscribers openModal={openFollowersModal} closeModal={() => setOpenFollowersModal(false)} />}
      {openFollowedModal && <FollowedSubscribed openModal={openFollowedModal} closeModal={() => setOpenFollowedModal(false)} />}

    </Layout>

  )
}

export default ProfileDetails