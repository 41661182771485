import React from 'react'

const Loader = () => {
  return (
    <div className="loader-wrapper" >
      <div className="d-flex align-items-center justify-content-center h-100">
        <div className="loader-inner ball-spin-fade-loader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  )
}

export default Loader