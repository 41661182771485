import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import FollowersTabData from '../tabs/FollowersTabData';
import SubscribersTabData from '../tabs/SubscribersTabData';


const FollowersSubscribers = ({ openModal, closeModal }) => {
  const [tabkey, setTabKey] = useState('followers');

  return (
    <Modal
      show={openModal}
      onHide={closeModal}
      centered
    >
      <Modal.Header>
        <Modal.Title>Guppies</Modal.Title>
        <button type='button' className='btn btn-close-modal' onClick={closeModal}>
          <i className="fa-solid fa-xmark"></i>
        </button>
      </Modal.Header>
      <Modal.Body>
        {/* <h1 className='modal-title-h1 mb-3 text-center'>Total Guppies 2110</h1> */}
        <div className='tab-container'>
          <Tabs
            id="followersTab"
            activeKey={tabkey}
            onSelect={(k) => setTabKey(k)}
            className="mb-4 justify-content-center tabs-dark"
          >
            <Tab eventKey="followers" title="Joined">
              {tabkey === "followers" &&
                <FollowersTabData />
              }
            </Tab>
            <Tab eventKey="subscribers" title="Subscribers">
              {tabkey === "subscribers" &&
                <SubscribersTabData />
              }
            </Tab>
          </Tabs>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default FollowersSubscribers