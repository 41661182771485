import React, { useEffect, useRef, useState } from 'react';
import { useGetUserInfoQuery } from '../../Redux/api/profileApi';
import { useParams } from 'react-router-dom';
import { constants } from '../../helper/constants';
import { showToast } from '../../helper/swal';
import InfiniteScroll from 'react-infinite-scroll-component';
import { removeSlash } from '../../helper/removeSlash';
import Images from '../Images';

const FollowersTabData = () => {
  const { id } = useParams();
  const [pageNo, setPageNo] = useState(1);
  const [pageSize] = useState(constants?.common?.pageSize);
  const [hasMoreFollowers, setHasMoreFollowers] = useState(false)
  const [followersList, setFollowersList] = useState([]);
  const PageNoRef = useRef(pageNo);

  const { data: followersData, isFetching: isfollowersDataFetching, error: followersListError } = useGetUserInfoQuery({
    userId: id,
    tabType: 4,
    pageNo: pageNo,
    pageSize: pageSize
  });

  // Followers List Response
  useEffect(() => {
    const handleFollowersListResponse = () => {
      if (PageNoRef === 1) {
        setFollowersList(followersData?.data?.data);
      } else {
        let nextFollowersList = followersData?.data?.data;
        setFollowersList((prevFollowersList) => [...prevFollowersList, ...nextFollowersList]);
      }
    };
    if (followersData) {
      handleFollowersListResponse();
      setHasMoreFollowers(followersData?.data?.data?.length === 10 ? true : false)
    } else if (followersListError) {
      showToast(followersListError?.data?.message, "error", true);
    }
  }, [followersData, followersListError])


  const fetchData = () => {
    setPageNo(pageNo + 1);
  }
  return (
    <div id="scrollablescrll" className='scroll-content'>
      <InfiniteScroll
        dataLength={followersList?.length}
        next={fetchData}
        hasMore={hasMoreFollowers}
        loader={<p className='text-center'>Loading...</p>}
        scrollableTarget="scrollablescrll"
      >
        {(followersList && followersList?.length) ?
          <ul className='user-list'>
            {followersList?.map((data, index) => (
              <li key={index}>
                <div className='user-left-col'>
                  <div className='user-thumb'>
                    <img src={data?.profileDetail?.profilePic ? process.env.REACT_APP_BASE_IMG_URL + removeSlash(data?.profileDetail?.profilePic) : Images?.PLACEHOLDER_IMG} alt='' />
                  </div>
                </div>
                <div className='user-right-col'>
                  <p>{data?.fullName}</p>
                </div>
              </li>
            ))}
          </ul>
          :
          <>
            {isfollowersDataFetching ?
              <p className='text-center'>Loading...</p>
              :
              <p className='text-center'>No Record Found</p>
            }
          </>
        }
      </InfiniteScroll>
    </div>
  )
}

export default FollowersTabData