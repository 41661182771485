export const numberFormatStyle = (data) => {
    if (data) {
        const number = Intl.NumberFormat('en', {
            notation: 'compact'
        });
        return number.format(data);
    } else {
        return 0
    }
};

export const currencyFormat = (data) => {
    if (data) {
        const currencyFormatter = Intl.NumberFormat('en', {
            notation: 'compact',
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return currencyFormatter.format(data);
    } else {
        return 0;
    }
};