import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'react-bootstrap';
import Layout from '../../components/layout';
import { useGetUserInfoQuery } from '../../Redux/api/profileApi';
import { showToast } from '../../helper/swal';
import Loader from '../../components/loader';
import { dateFormat } from '../../helper/dateFormat';
import Images from '../../components/Images';
import UserInfoTab from '../../components/tabs/UserInfoTab';
import { removeSlash } from '../../helper/removeSlash';

const PersonalDetails = () => {
  const { id } = useParams();

  const [userPersonalInfo, setUserPersonalInfo] = useState({
    createdAt: "",
    email: "",
    fullName: "",
    profileDetail: {
      username: "",
      bio: "",
      profilePic: ""
    }
  })

  const { refetch: refetchUserPersonalData, data: userPersonalData, isFetching: isUserPersonalDataFetching, isSuccess: isUserPersonalDataSuccess, error: userPersonalDataError } = useGetUserInfoQuery({
    userId: id,
    tabType: 1
  });

  useEffect(() => {
    // Refetch User Personal Info
    refetchUserPersonalData();
  }, [refetchUserPersonalData])


  // User Personal Info Response
  useEffect(() => {
    if (isUserPersonalDataSuccess) {
      setUserPersonalInfo({
        createdAt: userPersonalData?.data?.data[0].createdAt,
        email: userPersonalData?.data?.data[0].email,
        fullName: userPersonalData?.data?.data[0].fullName,
        profileDetail: {
          username: userPersonalData?.data?.data[0].profileDetail?.username,
          bio: userPersonalData?.data?.data[0].profileDetail?.bio,
          profilePic: userPersonalData?.data?.data[0].profileDetail?.profilePic
        }
      });
    } else if (userPersonalDataError) {
      showToast(userPersonalDataError?.data?.message, "error", true)
    }
  }, [userPersonalData, isUserPersonalDataSuccess, userPersonalDataError])

  return (
    <Layout>
      {isUserPersonalDataFetching ? <Loader /> : null}
      <div className='conent-header'>
        <h1 className='title-h1'>User Details</h1>
        <Breadcrumb>
          <BreadcrumbItem linkAs="span">
            <Link to="/users">All Users</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>User Details</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div className="tab-container">
        <UserInfoTab />
        <div className='card'>
          <div className='card-body'>
            <div className="tab-content">
              <div className="tab-pane active">
                <Row>
                  <Col xl={2} sm={3}>
                    <div className="form-group d-flex align-items-center flex-column mb-0">
                      <div className="img-thumb-box">
                        <div className="img-thumb">
                          <img src={userPersonalInfo?.profileDetail?.profilePic ? process.env.REACT_APP_BASE_IMG_URL + removeSlash(userPersonalInfo?.profileDetail?.profilePic) : Images?.PLACEHOLDER_IMG} alt="" />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl={10} sm={9}>
                    <Row>
                      <Col sm={6}>
                        <div className="mb-3">
                          <label className='form-label'>Name</label>
                          <p className='mb-0'>{userPersonalInfo?.fullName}</p>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3">
                          <label className='form-label'>Username</label>
                          <p className='mb-0 text-break'>{userPersonalInfo?.profileDetail?.username}</p>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3">
                          <label className='form-label'>Email Address</label>
                          <p className='mb-0 text-break'>{userPersonalInfo?.email}</p>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3">
                          <label className='form-label'>Date Created</label>
                          <p className='mb-0'>{dateFormat(userPersonalInfo?.createdAt)}</p>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <label className='form-label'>Bio</label>
                          <p className='mb-0'>{userPersonalInfo?.profileDetail?.bio ? userPersonalInfo?.profileDetail?.bio : "N/A"}</p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PersonalDetails