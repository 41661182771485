import React, { useEffect, useState } from 'react';
import { Breadcrumb, BreadcrumbItem, Col, Row, Button } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup"
import Layout from '../../components/layout';
import Images from '../../components/Images';
import { usePreSignedUrlMutation, useUpdateImageMutation } from '../../Redux/api/preSignedUrlApi';
import { constants } from '../../helper/constants';
import { useGetUserInfoQuery } from '../../Redux/api/profileApi';
import { showToast } from '../../helper/swal';
import { dateFormat } from '../../helper/dateFormat';
import Loader from '../../components/loader';
import { removeSlash } from '../../helper/removeSlash';
import { useEditUserMutation } from '../../Redux/api/userApi';


const EditUser = () => {
  const initialValues = {
    createdAt: "",
    fullName: "",
    username: "",
    email: "",
    bio: "",
    profilePic: null
  }

  const [imgUrl, setImgUrl] = useState(null);
  const [formData, setFormData] = useState(initialValues);
  const [fileSelected, setFileSelected] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const [preSignedUrl, { isLoading: isPreSignedUrlLoading }] = usePreSignedUrlMutation();
  const [updateImage, { isLoading: isUpdateImageLoading }] = useUpdateImageMutation();

  const { refetch: refetchUserPersonalData, data: userPersonalData, isFetching: isUserPersonalDataFetching, isSuccess: isUserPersonalDataSuccess, error: userPersonalDataError } = useGetUserInfoQuery({
    userId: id,
    tabType: 1
  });

  const [editUser, { data: editUserData, isLoading: isEditUserLoading, isSuccess: isEditUserSuccess, error: editUserError }] = useEditUserMutation();

  //Form Validtaion
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required(constants?.validations?.required),
    bio: Yup.string().required(constants?.validations?.required),
    profilePic: Yup.string().nullable()
  });

  // Upload Image
  const handleFileChange = (event, form, field) => {
    if (event.target.files && event.target.files.length) {
      setFileSelected(true);
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        form.setFieldValue(field.name, file);
        setImgUrl(URL.createObjectURL(file));
      };
      reader.readAsDataURL(file);
    }
  };

  // handle Submit
  const handleSubmit = async (values) => {
    if (fileSelected) {
      const imgObj = await preSignedUrl({
        type: "put",
        files: [
          {
            folderName: "profile",
            fileName: values.image.name
          }
        ]
      })
      await updateImage({
        url: imgObj?.data?.data[0]?.signedUrl,
        file: values.image
      })
    }
    const obj = {
      fullName: values?.fullName,
      bio: values?.bio,
      profilePic: fileSelected ? 'profile/' + values.image.name : values.profilePic ? values.profilePic : "",
    }
    await editUser({ data: obj, userId: id });
  }

  useEffect(() => {
    // Refetch User Personal Info
    refetchUserPersonalData();
  }, [refetchUserPersonalData])

  // User Personal Info Response
  useEffect(() => {
    if (isUserPersonalDataSuccess) {
      setImgUrl(userPersonalData?.data?.data[0].profileDetail?.profilePic && process.env.REACT_APP_BASE_IMG_URL + removeSlash(userPersonalData?.data?.data[0].profileDetail?.profilePic));
      setFormData({
        createdAt: dateFormat(userPersonalData?.data?.data[0].createdAt),
        email: userPersonalData?.data?.data[0].email,
        fullName: userPersonalData?.data?.data[0].fullName,
        username: userPersonalData?.data?.data[0].profileDetail?.username,
        bio: userPersonalData?.data?.data[0].profileDetail?.bio,
        profilePic: userPersonalData?.data?.data[0].profileDetail?.profilePic
      });
    } else if (userPersonalDataError) {
      showToast(userPersonalDataError?.data?.message, "error", true)
    }
  }, [userPersonalData, isUserPersonalDataSuccess, userPersonalDataError])


  // Update user api Response
  useEffect(() => {
    if (isEditUserSuccess) {
      if (editUserData?.success) {
        showToast(editUserData?.message, "success", false, 2000);
        navigate("/users");
      } else {
        showToast(editUserData?.message, "error", true)
      }
    } else if (editUserError) {
      showToast(editUserError?.data?.message, "error", true)
    }
  }, [isEditUserSuccess, editUserData, editUserError, navigate])

  return (
    <Layout>
      {(isUserPersonalDataFetching || isPreSignedUrlLoading || isUpdateImageLoading || isEditUserLoading) ? <Loader /> : null}
      <div className='conent-header'>
        <h1 className='title-h1'>Edit User</h1>
        <Breadcrumb>
          <BreadcrumbItem linkAs="span">
            <Link to="/users">Users List</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Edit User</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <Formik initialValues={formData} enableReinitialize validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ handleSubmit, setFieldValue }) => {
          const handleRemoveImage = () => {
            setFieldValue('profilePic', '');
            setImgUrl(null);
            setFileSelected(false)
          };

          return (
            <Form onSubmit={handleSubmit}>
              <div className='card'>
                <div className='card-body'>
                  <Row>
                    <Col xl={2} md={3}>
                      <div className="form-group d-flex align-items-center flex-column mb-0">
                        <div className="img-thumb-box">
                          <div className="img-thumb">
                            <img src={imgUrl ? imgUrl : Images.PLACEHOLDER_IMG} alt="" />
                          </div>
                          <Field name="image">
                            {({ field, form }) => (
                              <label className="upload-file-wrap">
                                <i className="fas fa-plus"></i>
                                <input type="file" name="profilePic" accept="image/png, image/jpeg,image/jpg" onChange={(event) => { handleFileChange(event, form, field) }} />
                              </label>
                            )}
                          </Field>
                        </div>
                        <ErrorMessage name="profilePic" component="div" className="error-text" />
                        <div>
                          <Button variant='primary' className='mt-3' onClick={handleRemoveImage}>Remove Image</Button>
                        </div>
                      </div>
                    </Col>
                    <Col xl={10} md={9}>
                      <Row>
                        <Col sm={6}>
                          <div className="mb-3">
                            <label className='form-label'>Name</label>
                            <Field type="text" className="form-control" name="fullName" />
                            <ErrorMessage name="fullName" component="div" className="error-text" />
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div className="mb-3">
                            <label className='form-label'>Username</label>
                            <Field type="text" className="form-control" name="username" disabled />
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div className="mb-3">
                            <label className='form-label'>Email Address</label>
                            <Field type="text" className="form-control" name="email" disabled />
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div className="mb-3">
                            <label className='form-label'>Date Created</label>
                            <Field type="text" className="form-control" name="createdAt" disabled />
                          </div>
                        </Col>
                        <Col sm={12}>
                          <div className="mb-3">
                            <label className='form-label'>Bio</label>
                            <Field as="textarea" className="form-control" name="bio" />
                            <ErrorMessage name="bio" component="div" className="error-text" />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className='text-center mt-4'>
                <Button type="submit" variant="primary" className='btn-size-xs'>Save</Button>
                <Link to="/users" className='btn btn-secondary btn-size-xs ms-2'>Cancel</Link>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Layout>

  )
}

export default EditUser