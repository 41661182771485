import * as CryptoJS from "crypto-js";

const key = process.env.REACT_APP_AES_SECRET_KEY;
const iv = process.env.REACT_APP_IV_SECRET_KEY;

export const encrypt = (dataToEncrypt) => {
  const encryptedData = CryptoJS.AES.encrypt(dataToEncrypt, CryptoJS.enc.Utf8.parse(key), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
    keySize: 256 / 8,  // 256 bits
  });

  // Convert the encrypted data to hex
  const data = encryptedData.ciphertext.toString(CryptoJS.enc.Hex);
  return data;
}

export const decrypt = (dataToDecrypt) => {
  const encryptedData = CryptoJS.enc.Hex.parse(dataToDecrypt);
  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: encryptedData },
    CryptoJS.enc.Utf8.parse(key),
    {
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
      keySize: 256 / 8, // 256 bits
    }
  );

  // Convert the decrypted data to UTF-8 format
  const data = decrypted.toString(CryptoJS.enc.Utf8)
  return data;
}
