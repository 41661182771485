
import { createApi} from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../../utils/baseQuery";

export const reportApi = createApi({
  reducerPath: "reportApi",
  baseQuery: baseQueryWithReauth,

  endpoints: (builder) => ({
    getReportList: builder.query({
      query: (data) => {
        const { pageNo, pageSize, searchParam, status, reportedFor, reportId } = data;
        const url = `pageNo=${pageNo}&pageSize=${pageSize}${searchParam ? `&search=${searchParam}` : ''}${status ? `&status=${status}` : ''}${reportedFor ? `&reportedFor=${reportedFor}` : ''}${reportId ? `&reportId=${reportId}` : ''}`
        return {
          url: `/user/v1/report-listing?${url}`
        }
      }
    }),
    editReport: builder.mutation({
      query: (body) => {
        return {
          url: `/post/v1/update-report-decision`,
          method: 'POST',
          headers: {},
          body
        }
      },
    }),
  }),
});

export const {
  useGetReportListQuery,
  useEditReportMutation
} = reportApi;