import React from 'react'
import ReactPaginate from 'react-paginate';

const CommonPagination = (props, onPageChange) => {
  return (
    <div className='d-flex justify-content-center mt-3'>
      <ReactPaginate
        breakLabel="..."
        nextLabel="»"
        onPageChange={props.onPageChange}
        pageCount={Math.ceil(props.total / props.pageSize)}
        previousLabel="«"
        renderOnZeroPageCount={null}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination mb-0"
        activeClassName="active"
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        forcePage={props.currentPage}
      />
    </div>
  );
}


export default CommonPagination