
import { createApi} from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../../utils/baseQuery";

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQueryWithReauth,
  
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (payload) => {
        return {
          url: '/user/v1/sign-in',
          method: 'POST',
          body: payload,
          headers: {
            // 'Content-type': 'application/json; charset=UTF-8',
            client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`
          },
        }
      },
    }),
    forgotPassword: builder.mutation({
      query: (payload) => {
        return {
          url: '/user/v1/forgot-password',
          method: 'POST',
          body: payload,
          headers: {
            client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`
          },
        }
      },
    }),
    verifyOtp: builder.mutation({
      query: (payload) => {
        return {
          url: '/user/v1/verify-otp',
          method: 'POST',
          body: payload,
          headers: {
            client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`
          },
        }
      },
    }),
    resendOtp: builder.mutation({
      query: (payload) => {
        return {
          url: '/user/v1/send-otp',
          method: 'POST',
          body: payload,
          headers: {
            client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`
          },
        }
      },
    }),
    resetPassword: builder.mutation({
      query: (payload) => {
        return {
          url: '/user/v1/reset-password',
          method: 'POST',
          body: payload,
          headers: {
            client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`
          },
        }
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useForgotPasswordMutation,
  useVerifyOtpMutation,
  useResetPasswordMutation,
  useResendOtpMutation
} = authApi;