import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Layout from '../../components/layout'
import { useDeleteCommentMutation, useGetCommentDetailQuery } from '../../Redux/api/contentApi';
import { showToast } from '../../helper/swal';
import Loader from '../../components/loader';
import Swal from 'sweetalert2';
import { constants } from '../../helper/constants';
import { removeSlash } from '../../helper/removeSlash';
import Images from '../../components/Images';
import { dateFormat } from '../../helper/dateFormat';

const CommentDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {refetch: refetchCommentDetail, data: commentDetailData, isFetching: isCommentDetailFetching, isSuccess: isCommentDetailSuccess, error: commentDetailError } = useGetCommentDetailQuery({
    commentId: id,
  });

  const [deleteComment, { data: deleteCommentData, isLoading: isDeleteCommentLoading, isSuccess: isDeleteCommentSuccess, error: deleteCommentError }] = useDeleteCommentMutation();

  const [commentData, setCommentData] = useState({
    comment: "",
    createdAt: "",
    profilePic: "",
    username: "",
    postId: "",
    _id: ""
  });

  // Delete Comment
  const handleDeleteComment = (getActionId, getCommentId) => {
    Swal.fire({
      title: 'Delete',
      text: constants?.validations?.deleteCommentText,
      icon: 'question',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      allowOutsideClick: false
    }).then(async (result) => {
      if (result.isConfirmed && result.value) {
        await deleteComment({ data: { isDeleted: true, postId: getActionId },  commentId: getCommentId })
      }
    })
  }

  // Refetch Comment Details
    useEffect(() => {
      refetchCommentDetail();
    }, [refetchCommentDetail])
  

  // Comment Detail Response
  useEffect(() => {
    if (isCommentDetailSuccess) {
      setCommentData({
        comment: commentDetailData?.data[0]?.comment,
        createdAt: commentDetailData?.data[0]?.createdAt,
        postId: commentDetailData?.data[0]?.postId,
        profilePic: commentDetailData?.data[0]?.user?.profileDetail?.profilePic,
        username: commentDetailData?.data[0]?.user?.profileDetail?.username,
        _id: commentDetailData?.data[0]?._id,
      });
    } else if (commentDetailError) {
      showToast(commentDetailError?.data?.message, "error", true)
    }
  }, [commentDetailData, isCommentDetailSuccess, commentDetailError])


  // Delete Comment API Response
  useEffect(() => {
    if (isDeleteCommentSuccess) {
      if (deleteCommentData?.success) {
        showToast(deleteCommentData?.message, "success", false, 1000);
        navigate(-1)
      } else {
        showToast(deleteCommentData?.message, "error", true)
      }
    } else if (deleteCommentError) {
      showToast(deleteCommentError?.data?.message, "error", true)
    }
  }, [isDeleteCommentSuccess, deleteCommentData, deleteCommentError, commentData, navigate])


  return (
    <Layout>
      {(isCommentDetailFetching || isDeleteCommentLoading) && <Loader />}
      <div className='conent-header'>
        <h1 className='title-h1'>Comment Detail</h1>
        <Breadcrumb>
          <BreadcrumbItem linkAs="span">
            <button className='btn btn-link btn-breadcrumb'  onClick={() => navigate(-1)}>Reporting Details</button>
          </BreadcrumbItem>
          <BreadcrumbItem active>Comment Detail</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <Row className="justify-content-xl-center">
        <Col xl={8}>
          <div className='card'>
            <div className='card-body comment-scroller'>
              {commentDetailData && commentDetailData?.data?.length ?
                <ul className='charity-list'>
                  <li>
                    <div className='comment-inline-row'>
                      <div className='comment-col-left'>
                        <div className='user-c-thumb'>
                          <img src={commentData?.profilePic ? process.env.REACT_APP_BASE_IMG_URL + removeSlash(commentData?.profilePic) : Images?.PLACEHOLDER_IMG} alt='' />
                        </div>
                      </div>
                      <div className='comment-col-right'>
                        <h6 className='mb-1'>{commentData?.username}</h6>
                        <p>{dateFormat(commentData?.createdAt)}</p>
                        <p className='mb-0'>
                          {commentData?.comment}
                        </p>
                      </div>
                    </div>
                    <ul className="action-btn-group">
                      <li>
                        <OverlayTrigger placement='top' overlay={<Tooltip>Delete</Tooltip>}>
                          <button type='button' className='btn btn-delete btn-icon' onClick={() => handleDeleteComment(commentData?.postId, commentData?._id)}>
                            <i className='fa fa-trash'></i>
                          </button>
                        </OverlayTrigger>
                      </li>
                    </ul>
                  </li>
                </ul> :
                <p className='text-center'>
                  No Comment Found
                </p>
              }
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  )
}

export default CommentDetail