import React from 'react'
import { Link, useParams } from 'react-router-dom'

const UserInfoTab = () => {
  const { id } = useParams();
  return (
    <div className='tab-list'>
      <ul className="nav nav-tabs justify-content-center mb-4" role="tablist">
        <li className="nav-item">
          <Link className={window.location.pathname.includes("personal-details") ? "nav-link active" : "nav-link"} to={`/users/personal-details/${id}`}>Personal</Link>
        </li>
        <li className="nav-item">
          <Link className={window.location.pathname.includes("profile-details") ? "nav-link active" : "nav-link"} to={`/users/profile-details/${id}`}>Profile</Link>
        </li>
        <li className="nav-item">
          <Link className={window.location.pathname.includes("earnings") ? "nav-link active" : "nav-link"} to={`/users/earnings/${id}`}>Earnings</Link>
        </li>
      </ul>
    </div>
  )
}

export default UserInfoTab