import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'react-bootstrap';
import Layout from '../../components/layout';
import Images from '../../components/Images';
import { useGetPostDetailQuery } from '../../Redux/api/contentApi';
import { showToast } from '../../helper/swal';
import Loader from '../../components/loader';
import { dateFormat } from '../../helper/dateFormat';
import { removeSlash } from '../../helper/removeSlash';
import { getLastPart } from '../../helper/getLastPart';
import { capitalizeStr } from '../../helper/capitalizeStr';
import { numberFormatStyle } from '../../helper/numberFormat';

const PostDetail = () => {
  const { id } = useParams();

  const { refetch: refetchPost, data: postDetailData, isFetching: isPostFetching, isSuccess: isPostSuccess, error: postError } = useGetPostDetailQuery({
    postId: id,
  });

  const [postData, setPostData] = useState({
    createdAt: '',
    isExclusive: '',
    likeCount: '',
    nomsCount: '',
    status: '',
    totalEngagement: '',
    path: '',
    type: '',
    profilePic: '',
    username: '',
    fullName: '',
    caption: '',
    isPostHasComment: ''
  });

  // Get Post Detail
  useEffect(() => {
    if (isPostSuccess) {
      setPostData((prevPostData) => ({
        ...prevPostData,
        createdAt: postDetailData?.data?.posts[0]?.createdAt,
        isExclusive: postDetailData?.data?.posts[0]?.isExclusive ? "Locked" : "Public",
        likeCount: postDetailData?.data?.posts[0]?.likeCount,
        nomsCount: postDetailData?.data?.posts[0]?.nomsCount,
        status: postDetailData?.data?.posts[0]?.status,
        totalEngagement: postDetailData?.data?.posts[0]?.totalEngagement,
        path: postDetailData?.data?.posts[0]?.medias[0]?.path,
        type: postDetailData?.data?.posts[0]?.medias[0]?.type,
        profilePic: postDetailData?.data?.posts[0]?.user?.profileDetail?.profilePic,
        username: postDetailData?.data?.posts[0]?.user?.profileDetail?.username,
        fullName: postDetailData?.data?.posts[0]?.user?.fullName,
        caption: postDetailData?.data?.posts[0]?.caption,
        isPostHasComment: postDetailData?.data?.posts[0]?.isPostHasComment
      }))
    } else if (postError) {
      showToast(postError?.data?.message, "error", true)
    }
  }, [isPostSuccess, postDetailData?.data, postError]);

  // Refetch post Detail
  useEffect(() => {
    refetchPost();
  }, [refetchPost])

  return (
    <Layout>
      {isPostFetching ? <Loader /> : null}
      <div className='conent-header'>
        <h1 className='title-h1'>Post Details</h1>
        <Breadcrumb>
          <BreadcrumbItem linkAs="span">
            <Link to="/content">Content Management</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Post Details</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div className='card'>
        <div className='card-body'>
          <Row>
            <Col xl={2} sm={3}>
              <div className="form-group d-flex align-items-center flex-column mb-0">
                <h5 className='mb-3'>Profile Image</h5>
                <div className="img-thumb-box">
                  <div className="img-thumb">
                    <img src={postData?.profilePic ? process.env.REACT_APP_BASE_IMG_URL + removeSlash(postData?.profilePic) : Images.PLACEHOLDER_IMG} alt="" />
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={10} sm={9}>
              <h5 className='mb-3'>Editor Details</h5>
              <Row className='mb-2'>
                <Col sm={4} xs={6}>
                  <div className="mb-3">
                    <label className='form-label'>Name</label>
                    <p className='mb-0'>{postData?.fullName}</p>
                  </div>
                </Col>
                <Col sm={4} xs={6}>
                  <div className="mb-3">
                    <label className='form-label'>Username</label>
                    <p className='mb-0 text-break'>{postData?.username}</p>
                  </div>
                </Col>
                <Col sm={4} xs={12}>
                  <div className="mb-3">
                    <label className='form-label'>Posted On</label>
                    <p className='mb-0'>{dateFormat(postData?.createdAt)}</p>
                  </div>
                </Col>
              </Row>
              <h5 className='mb-3'>Post Insights</h5>
              <Row className='mb-2'>
                <Col xs={4}>
                  <div className="mb-3">
                    <label className='form-label'>Total Engagements</label>
                    <p className='mb-0'>{numberFormatStyle(postData?.totalEngagement)}</p>
                  </div>
                </Col>
                <Col xs={4}>
                  <div className="mb-3">
                    <label className='form-label'>Likes</label>
                    <p className='mb-0'>{numberFormatStyle(postData?.likeCount)}</p>
                  </div>
                </Col>
                <Col xs={4}>
                  <div className="mb-3">
                    <label className='form-label'>Noms</label>
                    <p className='mb-0'>{numberFormatStyle(postData?.nomsCount)}</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <div className="mb-3">
                    <label className='form-label'>Post Type</label>
                    <p className='mb-0'>{postData?.isExclusive}</p>
                  </div>
                </Col>
                <Col xs={4}>
                  <div className="mb-3">
                    <label className='form-label'>Post Status</label>
                    <p className='mb-0'>{capitalizeStr(postData?.status)}</p>
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="mb-3">
                    <label className='form-label'>Caption</label>
                    <p className='mb-0'>{postData?.caption ? postData?.caption : "N/A"}</p>
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="mb-3">
                    <label className='form-label'>Media</label>
                    {
                      postData?.type === "image" ?
                        (
                          <div className="img-thumb-box">
                            <div className='img-thumb'>
                              <img src={process.env.REACT_APP_BASE_IMG_URL + 'post/' + getLastPart(postData?.path)} alt="" />
                            </div>
                          </div>
                        ) :
                        postData?.type === "video" ?
                          (
                            <div className="img-thumb-box">
                              <div className="media-thumb">
                                <video controls>
                                  <source src={process.env.REACT_APP_BASE_IMG_URL + 'post/' + getLastPart(postData?.path)} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                              </div>
                            </div>
                          ) :
                          postData?.type === "audio" ?
                            (
                              <div className="img-thumb-box">
                                <audio controls>
                                  <source src={process.env.REACT_APP_BASE_IMG_URL + 'post/' + getLastPart(postData?.path)} type="audio/mpeg" />
                                  <p>Your user agent does not support the HTML5 Audio element.</p>
                                </audio>
                              </div>
                            ) :
                            postData?.type === "text" ?
                              (
                                <p>{postData?.path}</p>
                              )
                              : null
                    }
                  </div>
                </Col>
                {postData?.isPostHasComment &&
                  <Col xs={12}>
                    <Link className='btn btn-primary mt-3' to={`/content/detail/comments/${id}`}>View Comments</Link>
                  </Col>
                }
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  )
}

export default PostDetail