import moment from 'moment';

// Common function to format a date using moment
export const dateFormat = (dateString) => {
  if (!dateString) {
    return "N/A"; // Return a placeholder for null or blank dates
  }

  const formattedDate = moment(dateString).format('YYYY-MM-DD');
  return formattedDate;
}