
import { createApi} from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../../utils/baseQuery";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQueryWithReauth,

  endpoints: (builder) => ({
    getUserList: builder.query({
      query: (data) => {
       
        const { pageNo, pageSize, userStatus, searchParam } = data;
        const url = `pageNo=${pageNo}&pageSize=${pageSize}${userStatus ? `&status=${userStatus}` : ''}${searchParam ? `&searchText=${searchParam}` : ''}`
        return {
          url: `/user/v1/admin-user-list?${url}`
        }
      }
    }),
    userStatus: builder.mutation({
      query: (body) => {
        const { userId } = body;
        return {
          url: `user/v1/block-unblock/${userId}`,
          method: 'PUT',
          headers: {},
        }
      },
    }),
    editUser: builder.mutation({
      query: (body) => {
        const {data, userId } = body;
        return {
          url: `user/v1/update-user-details/${userId}`,
          method: 'POST',
          headers: {},
          body: data
        }
      },
    }),
    deleteUser: builder.mutation({
      query: (body) => {
        return {
          url: `/user/v1/delete-account`,
          method: 'POST',
          headers: {},
          body
        }
      },
    }),
  }),
});

export const {
  useGetUserListQuery,
  useUserStatusMutation,
  useDeleteUserMutation,
  useEditUserMutation
} = userApi;