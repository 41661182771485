const noTokenEndpoint = [
  {
    name: "login",
  },
  {
    name: "forgotPassword",
  },
  {
    name: "verifyOtp",
  },
  {
    name: "resendOtp",
  },
  {
    name: "resetPassword",
  },
  {
    name: "preSignedUrl",
  },
  {
    name: "updateImage",
  },
];

export default noTokenEndpoint;