import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import Loader from '../../components/loader';
import { constants } from '../../helper/constants';
import NoRecordsFound from '../../components/shared/NoRecordsFound';
import CommonPagination from '../../components/shared/CommonPagination';
// import { debounce } from 'lodash';
import { showToast } from '../../helper/swal';
import { currencyFormat, numberFormatStyle } from '../../helper/numberFormat';
import { useParams } from 'react-router-dom';
import { useGetWithdrawalHistoryQuery } from '../../Redux/api/paymentApi';
import { dateFormat } from '../../helper/dateFormat';

const WithdrawalTabData = () => {
  const { id } = useParams();
  const [pageNo, setPageNo] = useState(constants?.common?.pageNo);
  const [pageSize, setPageSize] = useState(constants?.common?.pageSize);
  const [totalCount, setTotalCount] = useState(0);
  // const [searchWithdrawal, setSearchWithdrawal] = useState("");
  const [withdrawalList, setWithdrawalList] = useState([]);

  const { refetch: refetchWithdrawalHistory, data: withdrawalHistoryData, isFetching: isWithdrawalHistoryFetching, isSuccess: isWithdrawalHistorySuccess, error: withdrawalHistoryError } = useGetWithdrawalHistoryQuery({
    userId: id,
    pageNo: pageNo + 1,
    pageSize: pageSize,
  });

  // Page Change
  const handlePageChange = (page) => {
    const selectedPageNo = setPageNo(page.selected);
    return selectedPageNo;
  }

  // Search Withdrawal
  // const handleWithdrawalSearch = debounce((e) => {
  //   setSearchWithdrawal(e.target.value);
  //   setPageNo(0);
  // }, 500)

  useEffect(() => {
    // Refetch Donations list on page change
    refetchWithdrawalHistory();
  }, [pageNo, pageSize, refetchWithdrawalHistory])

  // Donations List Response
  useEffect(() => {
    if (isWithdrawalHistorySuccess) {
      setWithdrawalList(withdrawalHistoryData?.data?.userWithdrawHistory);
      setTotalCount(withdrawalHistoryData?.data?.totalCount)
    } else if (withdrawalHistoryError) {
      showToast(withdrawalHistoryError?.data?.message, "error", true)
    }
  }, [withdrawalHistoryData, isWithdrawalHistorySuccess, withdrawalHistoryError])

  return (
    <>
      {(isWithdrawalHistoryFetching) && <Loader />}
      <div className='card'>
        <div className='card-body'>
          <div className='card-filter'>
            <Row className="justify-content-end">
              <Col lg={2} sm={4}>
                <Form.Group className='mb-3'>
                  <Form.Label>Page Options</Form.Label>
                  <Form.Select onChange={(e) => { setPageSize(e.target.value); setPageNo(0) }}>
                    {constants?.pageOptions?.map((item, index) => (
                      <option value={item.value} key={index}>{item.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              {/* <Col xl={2} lg={3} xs={6}>
                <Form.Group className='mb-3'>
                  <Form.Label>Search Charity</Form.Label>
                  <div className="has-search-icon">
                    <i className="fa fa-search has-icon"></i>
                    <Form.Control placeholder="Search by charity" onChange={handleWithdrawalSearch} />
                  </div>
                </Form.Group>
              </Col> */}
            </Row>
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Coins</th>
                <th>Amount</th>
                <th>Request Date</th>
                <th>Processed Date</th>
                {/* <th>Receipt</th> */}
              </tr>
            </thead>
            <tbody>
              {withdrawalList && withdrawalList?.length ?
                <>
                  {withdrawalList?.map((data, index) => (
                    <tr key={index}>
                      <td>{pageNo * pageSize + index + 1}</td>
                      <td>{numberFormatStyle(data?.coin)}</td>
                      <td>{currencyFormat(data?.amount)}</td>
                      <td>{dateFormat(data?.createdAt)}</td>
                      <td>{dateFormat(data?.withdrawalAt)}</td>
                      {/* <td>{data?.receipt}</td> */}
                    </tr>
                  ))
                  }
                </>
                :
                <NoRecordsFound colspan={6} />
              }
            </tbody>
          </Table>
          <CommonPagination total={totalCount} pageSize={pageSize} currentPage={pageNo} onPageChange={handlePageChange} />
        </div>
      </div>
    </>

  )
}

export default WithdrawalTabData