import { createSlice } from "@reduxjs/toolkit";


const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
  },
  reducers: {
    loginUser: (state, action) => {
      localStorage.setItem('user', JSON.stringify(action.payload?.data?.accessToken));
      state.user = action.payload.data;
    },
    logoutUser: (state) => {
      localStorage.clear();
      state.user = null;
    },
    clearAuth: (state) => {
      state.user = null;
    },
    updateRefeshTokenAndToken: (state, action) => {
      let tokenInfo = action.payload;
      state.user = { ...state.user, ...tokenInfo }
    },
  },
});

// this is for dispatch
export const { loginUser, logoutUser, clearAuth, updateRefeshTokenAndToken } = authSlice.actions;

// this is for configureStore
export default authSlice.reducer;