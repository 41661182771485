import React, { useEffect, useState } from 'react'
import Layout from '../../../components/layout'
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { useGetPayoutDetailQuery } from '../../../Redux/api/paymentApi'
import { showToast } from '../../../helper/swal'
import { dateFormat } from '../../../helper/dateFormat'
import Loader from '../../../components/loader'
import { currencyFormat, numberFormatStyle } from '../../../helper/numberFormat'

const PayoutDetail = () => {
  const { id } = useParams();

  const { refetch: refetchPayoutDetail, data: payoutDetailData, isFetching: isPayoutDetailFetching, isSuccess: isPayoutDetailSuccess, error: payoutDetailError } = useGetPayoutDetailQuery({
    payoutId: id,
  });

  const [payoutData, setPayoutData] = useState({
    amount: '',
    coin: '',
    createdAt: '',
    email: '',
    fullName: '',
    payoutMethod: '',
    percentToDonate: '',
    userId: '',
    username: '',
    withdrawalAt: '',
  });

  // Get Payout Detail
  useEffect(() => {
    if (isPayoutDetailSuccess) {
      setPayoutData((prevPayoutData) => ({
        ...prevPayoutData,
        amount: payoutDetailData?.data?.payout[0]?.amount,
        coin: payoutDetailData?.data?.payout[0]?.coin,
        email: payoutDetailData?.data?.payout[0]?.email,
        fullName: payoutDetailData?.data?.payout[0]?.fullName,
        payoutMethod: payoutDetailData?.data?.payout[0]?.payoutMethod,
        userId: payoutDetailData?.data?.payout[0]?.userId,
        username: payoutDetailData?.data?.payout[0]?.username,
        withdrawalAt: payoutDetailData?.data?.payout[0]?.withdrawalAt,
      }))
    } else if (payoutDetailError) {
      showToast(payoutDetailError?.data?.message, "error", true)
    }
  }, [isPayoutDetailSuccess, payoutDetailData?.data, payoutDetailError]);

  // Refetch Payout Detail
  useEffect(() => {
    refetchPayoutDetail();
  }, [refetchPayoutDetail])

  return (
    <Layout>
      {(isPayoutDetailFetching) && <Loader />}
      <div className='conent-header'>
        <h1 className='title-h1'>Payout Details</h1>
        <Breadcrumb>
          <BreadcrumbItem linkAs="span">
            <Link to="/payments/payouts">Payouts</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Payout Details</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div className='card'>
        <div className='card-body'>
          <Row>
            <Col md={4} xs={6}>
              <div className="mb-3">
                <label className='form-label'>Name</label>
                <p className='mb-0'>{payoutData?.fullName}</p>
              </div>
            </Col>
            <Col md={4} xs={6}>
              <div className="mb-3">
                <label className='form-label'>Username</label>
                <p className='mb-0 text-break'>{payoutData?.username}</p>
              </div>
            </Col>
            <Col md={4} xs={6}>
              <div className="mb-3">
                <label className='form-label'>Email</label>
                <p className='mb-0 text-break'>{payoutData?.email}</p>
              </div>
            </Col>
            <Col md={4} xs={6}>
              <div className="mb-3">
                <label className='form-label'>Requested Date</label>
                <p className='mb-0'>{dateFormat(payoutData?.withdrawalAt)}</p>
              </div>
            </Col>
            <Col md={4} xs={6}>
              <div className="mb-3">
                <label className='form-label'>Payout Method</label>
                <p className='mb-0'>{payoutData?.payoutMethod}</p>
              </div>
            </Col>
            <Col md={4} xs={6}>
              <div className="mb-3">
                <label className='form-label'>Coins</label>
                <p className='mb-0'>{numberFormatStyle(payoutData?.coin)}</p>
              </div>
            </Col>
            <Col md={4} xs={6}>
              <div className="mb-3">
                <label className='form-label'>Amout</label>
                <p className='mb-0'>{currencyFormat(payoutData?.amount/100)}</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  )
}

export default PayoutDetail