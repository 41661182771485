import React, { useEffect, useState } from 'react';
import { Col, Form, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import Layout from '../../components/layout';
import { Link } from 'react-router-dom';
import { useGetReportListQuery } from '../../Redux/api/reportApi';
import { constants } from '../../helper/constants';
import { showToast } from '../../helper/swal';
import Loader from '../../components/loader';
import NoRecordsFound from '../../components/shared/NoRecordsFound';
import { dateFormat } from '../../helper/dateFormat';
import CommonPagination from '../../components/shared/CommonPagination';
import { debounce } from 'lodash';
import { capitalizeStr } from '../../helper/capitalizeStr';

const ReportManagement = () => {

  const [pageNo, setPageNo] = useState(constants?.common?.pageNo);
  const [pageSize, setPageSize] = useState(constants?.common?.pageSize);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const [reportedFor, setReportedFor] = useState("");
  const [reportList, setReportList] = useState([]);

  const { refetch: refetchReportList, data: reportListData, isFetching: isReportListFetching, isSuccess: isReportListSuccess, error: reportListError } = useGetReportListQuery({
    pageNo: pageNo + 1,
    pageSize: pageSize,
    searchParam: searchText,
    status: status,
    reportedFor: reportedFor
  });

  // Page Change
  const handlePageChange = (page) => {
    const selectedPageNo = setPageNo(page.selected);
    return selectedPageNo;
  }

  //Get Report Status
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    setPageNo(0);
  }

  //Get Reported Reason
  const handleReportedChange = (e) => {
    setReportedFor(e.target.value);
    setPageNo(0);
  }

  // Search User
  const handleReportSearch = debounce((e) => {
    setSearchText(e.target.value);
    setPageNo(0)
  }, 500)


  // Report List Response
  useEffect(() => {
    if (isReportListSuccess) {
      setReportList(reportListData?.data?.report);
      setTotalCount(reportListData?.data?.totalCount)
    } else if (reportListError) {
      showToast(reportListError?.data?.message, "error", true)
    }
  }, [reportListData, isReportListSuccess, reportListError])


  useEffect(() => {
    // Refetch Report list on page change
    refetchReportList();
  }, [pageNo, pageSize, status, searchText, reportedFor, refetchReportList])

  return (
    <Layout>
      {(isReportListFetching) && <Loader />}
      <div className='conent-header'>
        <h1 className='title-h1'>Reporting</h1>
      </div>
      <div className='card'>
        <div className='card-body'>
          <div className='card-filter'>
            <Row className="justify-content-end">
              <Col xl={2} sm={3} xs={6}>
                <Form.Group className='mb-3'>
                  <Form.Label>Page Options</Form.Label>
                  <Form.Select onChange={(e) => { setPageSize(e.target.value); setPageNo(0) }}>
                    {constants?.pageOptions?.map((item, index) => (
                      <option value={item.value} key={index}>{item.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xl={2} sm={3} xs={6}>
                <Form.Group className='mb-3'>
                  <Form.Label>Reported For</Form.Label>
                  <Form.Select onChange={handleReportedChange}>
                    <option value="">All</option>
                    {constants?.reportedFor?.map((item, index) => (
                      <option value={item.value} key={index}>{item.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xl={2} sm={3} xs={6}>
                <Form.Group className='mb-3'>
                  <Form.Label>Status</Form.Label>
                  <Form.Select onChange={handleStatusChange}>
                    <option value="">All</option>
                    {constants?.reportStatus?.map((item, index) => (
                      <option value={item.value} key={index}>{item.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xl={3} sm={3} xs={6}>
                <Form.Group className='mb-3'>
                  <Form.Label>Search</Form.Label>
                  <div className="has-search-icon">
                    <i className="fa fa-search has-icon"></i>
                    <Form.Control placeholder="Search by User Name" onChange={handleReportSearch} />
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Reported User</th>
                <th>Reporting User</th>
                <th>Reported For</th>
                <th>Reported On</th>
                <th>Decision</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {reportList && reportList?.length ?
                <>
                  {reportList?.map((data, index) => (
                    <tr key={index}>
                      <td>{pageNo * pageSize + index + 1} </td>
                      <td>{data?.toUsername ? data?.toUsername : "--"}</td>
                      <td>{data?.fromUsername ? data?.fromUsername : "--"}</td>
                      <td>{capitalizeStr(data?.reportType)}</td>
                      <td>{dateFormat(data?.createdAt)}</td>
                      <td>{capitalizeStr(data?.decisionStatus)}</td>
                      <td>{capitalizeStr(data?.status)}</td>
                      <td>
                        <ul className="action-btn-group">
                          <li>
                            <OverlayTrigger placement='top' overlay={<Tooltip>View</Tooltip>}>
                              <Link className='btn btn-icon btn-view' to={`/reports/detail/${data?._id}`}>
                                <i className="fa-regular fa-eye"></i>
                              </Link>
                            </OverlayTrigger>
                          </li>
                          <li>
                            <OverlayTrigger placement='top' overlay={<Tooltip>Edit</Tooltip>}>
                              <Link className='btn btn-icon btn-edit' to={`/reports/edit/${data?._id}`}>
                                <i className="fa-regular fa-pen-to-square"></i>
                              </Link>
                            </OverlayTrigger>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  ))
                  }
                </> :
                <NoRecordsFound colspan={8} />
              }
            </tbody>
          </Table>
          <CommonPagination total={totalCount} pageSize={pageSize} currentPage={pageNo} onPageChange={handlePageChange} />
        </div>
      </div>
    </Layout>
  )
}


export default ReportManagement