import { Routes, Route } from "react-router-dom";
import Login from "../pages/auth/Login";
import UserManagement from "../pages/userManagement";
import CharityManagement from "../pages/charityManagement";
import SearchCharity from "../pages/charityManagement/SearchCharity";
import PrivateRoute from "./PrivateRoutes";
import EditCharity from "../pages/charityManagement/EditCharity";
import CharityDetail from "../pages/charityManagement/CharityDetail";
import ChangePassword from "../pages/auth/ChangePassword";
import ForgotPassword from "../pages/auth/ForgotPassword";
import VerifyOtp from "../pages/auth/VerifyOtp";
import ResetPassword from "../pages/auth/ResetPassword";
import ContentManagement from "../pages/contentManagement";
import ReportManagement from "../pages/reportManagement";
import ReportDetail from "../pages/reportManagement/ReportDetail";
import EditReport from "../pages/reportManagement/EditReport";
import PersonalDetails from "../pages/userManagement/PersonalDetails";
import ProfileDetails from "../pages/userManagement/ProfileDetails";
import Earnings from "../pages/userManagement/Earnings";
import PostDetail from "../pages/contentManagement/PostDetail";
import Subscriptions from "../pages/payments/subscriptions";
import Donations from "../pages/payments/donations";
import Payouts from "../pages/payments/payouts";
import PayoutDetail from "../pages/payments/payouts/PayoutDetail";
import CommentList from "../pages/contentManagement/CommentList";
import CommentDetail from "../pages/contentManagement/CommentDetail";
import EditUser from "../pages/userManagement/EditUser";



const BaseRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify-otp" element={<VerifyOtp />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="change-password" element={<PrivateRoute><ChangePassword /></PrivateRoute>} />
        <Route path="/users" element={<PrivateRoute><UserManagement /></PrivateRoute>} />
        <Route path="/users/edit/:id" element={<PrivateRoute><EditUser /></PrivateRoute>} />
        <Route path="/users/personal-details/:id" element={<PrivateRoute><PersonalDetails /></PrivateRoute>} />
        <Route path="/users/profile-details/:id" element={<PrivateRoute><ProfileDetails /></PrivateRoute>} />
        <Route path="/users/earnings/:id" element={<PrivateRoute><Earnings /></PrivateRoute>} />
        <Route path="/content" element={<PrivateRoute><ContentManagement /></PrivateRoute>} />
        <Route path="/content/detail/:id" element={<PrivateRoute><PostDetail /></PrivateRoute>} />
        <Route path="/content/detail/comments/:id" element={<PrivateRoute><CommentList /></PrivateRoute>} />
        <Route path="/content/detail/comments/detail/:id" element={<PrivateRoute><CommentDetail /></PrivateRoute>} />
        <Route path="/charity" element={<PrivateRoute><CharityManagement /></PrivateRoute>} />
        <Route path="charity/search" element={<PrivateRoute><SearchCharity /></PrivateRoute>} />
        <Route path="charity/edit/:id" element={<PrivateRoute><EditCharity /></PrivateRoute>} />
        <Route path="charity/detail/:id" element={<PrivateRoute><CharityDetail /></PrivateRoute>} />
        <Route path="/reports" element={<PrivateRoute><ReportManagement /></PrivateRoute>} />
        <Route path="/reports/detail/:id" element={<PrivateRoute><ReportDetail /></PrivateRoute>} />
        <Route path="/reports/edit/:id" element={<PrivateRoute><EditReport /></PrivateRoute>} />
        <Route path="/payments/subscriptions" element={<PrivateRoute><Subscriptions /></PrivateRoute>} />
        <Route path="/payments/donations" element={<PrivateRoute><Donations /></PrivateRoute>} />
        <Route path="/payments/payouts" element={<PrivateRoute><Payouts /></PrivateRoute>} />
        <Route path="/payments/payouts/detail/:id" element={<PrivateRoute><PayoutDetail /></PrivateRoute>} />
      </Routes>
    </>
  )
}

export default BaseRoutes;
